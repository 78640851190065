export default {
  nl: {
    confirmSection: {
      title: 'De afspraak is gemaakt!',
      agenda: 'Voeg toe aan je agenda',
      changeAppointment: 'Komt het toch niet uit op deze datum?',
      changeAppointmentDescription: 'Wij begrijpen dat planningen kunnen wijzigen. Pas je afspraak met de monteur eenvoudig aan of annuleer deze hieronder.',
      change: 'Afspraak wijzigen',
      cancel: 'Afspraak annuleren',
      changeAvailability: 'Beschikbaarheid aanpassen',
      confirmedAppointment: 'Onze monteur arriveert [shortDate] tussen [startTime] en [endTime].',
      confirmedAppointmentDetails: 'Je ontvangt een e-mail ter bevestiging met meer informatie over wat je kan verwachten tijdens het bezoek.',
      averageVisit: 'Dit bezoek duurt gemiddeld [b][duration][/b], het is afhankelijk van de situatie bij jou thuis',
      expertOnWay: 'Onze monteur is onderweg.',
      expertPassedBy: 'Onze monteur is langsgeweest.',
      whosComing: 'Wie komt er langs?',
      whoWasThere: 'Wie kwam er langs?',
      aboutYourVisit: 'Over je bezoek',
      selectedNoTopSlotsTitle: 'Bedankt voor het opgeven van jouw beschikbaarheid!',
      selectedNoTopSlotsDescription: 'Wij zullen een afspraak voor je inplannen en per mail een afspraakbevestiging sturen zodra deze is gemaakt.',
      selectedNoTopSlotsContentCardTitle: 'We hebben de volgende beschikbare momenten van je ontvangen:',
      selectedNoTopSlotsContentTitle: 'Bedankt voor het doorgeven!',
      feedbackTitle: 'Hoe vond je het maken van de afspraak?',
    },
  },
  en: {
    confirmSection: {
      title: 'The appointment is scheduled!',
      agenda: 'Add to your calendar',
      changeAppointment: 'Does this date not work for you?',
      changeAppointmentDescription: 'We understand that schedules can change. Easily reschedule or cancel your appointment with the expert below.',
      change: 'Change Appointment',
      cancel: 'Cancel Appointment',
      changeAvailability: 'Change Availability',
      confirmedAppointment: 'Our expert arrives on [shortDate] between [startTime] and [endTime] hours.',
      confirmedAppointmentDetails: 'You will receive a confirmation email with more information about what you can expect during your visit.',
      averageVisit: 'The visit lasts for approximately [b][duration][/b] and depends on the situation in your home',
      expertOnWay: 'Our expert is on the way.',
      expertPassedBy: 'Our expert has stopped by.',
      whosComing: 'Who is coming by?',
      whoWasThere: 'Who came by?',
      aboutYourVisit: 'About your visit',
      selectedNoTopSlotsTitle: 'Thank you for providing us with your availability!',
      selectedNoTopSlotsDescription: 'We will schedule an appointment for you and send a confirmation via email.',
      selectedNoTopSlotsContentCardTitle: 'We have received the following available moments from you:',
      selectedNoTopSlotsContentTitle: 'Thank you for letting us know!',
      feedbackTitle: 'What do you think about the appointment booking process?',
    },
  }
};
