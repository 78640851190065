import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import style from './WhatsAppLink.module.scss';
import { TicketContext } from '../../contexts/TicketContext';
import Button from '../Button';
import { ActionContainer } from '../ActionContainer/ActionContainer';
import { StylesheetContext } from '../../contexts/StylesheetContext';


const WhatsAppLink: FC = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { ticket } = useContext(TicketContext);
  const { stylesheet } = useContext(StylesheetContext);

  if (!ticket) {
    return null;
  }

  const emptySpaceCondition = /\s/g;

  // In case there is a misconfigurion on backend this removes spaces between numbers if present
  const formattedContactNumber = (stylesheet.contact.whatsappNumber || '').replace(emptySpaceCondition, '');

  return (
    <ActionContainer bgClassName={style.containerBG} innerStyle={style.containerInner}>
      <div className={style.row}>
        <span className={style.header}>{t('contact:chatWithWhatsApp')}</span>
        <img width={64} height={64} alt="whatsapp" src="/assets/green_callouts.png" />
      </div>
      <p className={style.p}><Trans i18nKey="contact:chatWithWhatsAppDescription"/></p>
      <Button
        img={<img alt="whatsapp" src="/assets/whatsapp.svg" />}
        onClick={() => window.open(`https://wa.me/${formattedContactNumber}`, '_blank')}
        text="Start chat"
        size="full"
      />
    </ActionContainer>
  );
};

export default WhatsAppLink;
