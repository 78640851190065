import { Ticket } from '../interfaces/Ticket';
import { Stylesheet } from '../interfaces/Stylesheet';
import StepsService from '../services/StepsService';
import { Signature } from '../interfaces/Signature';
import { SupportedErrorTypes } from './ErrorService';
import { isTicketCancellable } from '../helpers/TicketHelpers';

export type InitType = 'cancel' | 'ticket' | 'signature';

interface CalculateInitialPageParams {
  ticket?: Ticket;
  stylesheet?: Stylesheet;
  signature?: Signature;
  initType?: string;
}

const errorBaseUrl = '/error';

const initTypes: Record<InitType, string> = {
  cancel: 'c',
  ticket: 't',
  signature: 'sign'
};

const getInitType = () => {
  const { href } = window.location;

  if (href.includes('cancel')) {
    return initTypes.cancel;
  } else if (href.includes('sign')) {
    return initTypes.signature;
  } else {
    return initTypes.ticket;
  }
};

const errorUrls: Record<keyof typeof SupportedErrorTypes, string> = {
  unexpectedError: `${errorBaseUrl}/${SupportedErrorTypes.unexpectedError}`,
  ticketNotFound: `${errorBaseUrl}/${SupportedErrorTypes.ticketNotFound}`,
  stylesheetNotFound: `${errorBaseUrl}/${SupportedErrorTypes.stylesheetNotFound}`,
  closed: `${errorBaseUrl}/${SupportedErrorTypes.closed}`,
  canceled: `${errorBaseUrl}/${SupportedErrorTypes.canceled}`,
  noAppointmentYet: `${errorBaseUrl}/${SupportedErrorTypes.noAppointmentYet}`,
  maintenance: `${errorBaseUrl}/${SupportedErrorTypes.maintenance}`,
  noSignatureFound: `${errorBaseUrl}/${SupportedErrorTypes.noSignatureFound}`,
  alreadyCompleted: `${errorBaseUrl}/${SupportedErrorTypes.alreadyCompleted}`,
  appointmentNotPossible: `${errorBaseUrl}/${SupportedErrorTypes.appointmentNotPossible}`
};

const getErrorUrlBasedOnStatus = (status: string): string => {
  const knownErrorStatuses: { [key: string]: string | undefined } = {
    'Planning On Hold': errorUrls.noAppointmentYet,
    Closed: errorUrls.closed,
    Canceled: errorUrls.canceled,
  };

  return knownErrorStatuses[status] || errorUrls.unexpectedError;
};

const calculateInitialPage = ({ ticket, stylesheet, signature, initType }: CalculateInitialPageParams): string => {
  if (initType === initTypes.signature && signature) {
    return '/sign/' + signature.hashId;
  }

  if (initType === initTypes.signature && !signature) {
    return errorUrls.noSignatureFound;
  }

  if (!ticket) {
    return errorUrls.ticketNotFound;
  }

  if (!stylesheet) {
    return errorUrls.stylesheetNotFound;
  }

  if (stylesheet && stylesheet.maintenance.status) {
    return errorUrls.maintenance;
  }

  const selfserviceFlowStatuses = ['Dispatched', 'In Progress', 'On Route', 'Completed', 'New', 'Execution On Hold'];

  if (selfserviceFlowStatuses.includes(ticket.workOrder.status)) {
    if (initType === initTypes.cancel && isTicketCancellable(ticket, stylesheet)) {
      return StepsService.getStepUri(ticket.hashId, 'cancel');
    }

    const steps = StepsService.getDefaultSteps(ticket);
    const activeStep = StepsService.getActiveStep(steps);

    return activeStep.uri;
  }

  return getErrorUrlBasedOnStatus(ticket.workOrder.status);
};

export { calculateInitialPage, initTypes, errorUrls, getInitType };
