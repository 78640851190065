import { getI18n } from 'react-i18next';

import i18n, { defaultLanguage } from './i18n';
import { EnabledLanguage } from '../interfaces/Language';
import { loadNewChatLanguage } from '../services/ChatService';

const isNl = (language: string): boolean => {
  return (language === 'nl-NL' || language === 'nl' || language === 'nl-nl' || language === 'dutch');
};

const isEn = (language: string): boolean => {
  return (language === 'en' || language === 'en-US');
};

const setLanguage = (language: string): void => {
  let languageToUse: EnabledLanguage;

  if (!language) {
    languageToUse = defaultLanguage;
  } else if (isNl(language)) {
    languageToUse = 'nl';
  } else if (isEn(language)) {
    languageToUse = 'en';
  } else {
    languageToUse = defaultLanguage;
  }
  i18n.changeLanguage(languageToUse, loadNewChatLanguage);
};

const getLanguage = (): EnabledLanguage => {
  const currentLanguage = getI18n().language;

  if (currentLanguage === 'en'
    || currentLanguage === 'nl')
    return currentLanguage;

  return defaultLanguage;
};

const getChatLanguage = (): string => {
  switch (getLanguage()) {
    case 'nl':
      return 'nl-NL';
    default:
      return 'en';
  }
};

export default {
  isNl,
  isEn,
  setLanguage,
  getLanguage,
  getChatLanguage
};
