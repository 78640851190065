import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Ticket } from '../../interfaces/Ticket';
import { Stylesheet } from '../../interfaces/Stylesheet';
import { useFormatting } from '../../services/hooks/useFormatting';

import NoSlotForm from '../../components/NoSlots/NoSlotForm';

import styles from './Appointment.module.scss';
import { NoTopSlot } from '../../interfaces/NoTopSlot';
import Alert from '../../components/Alert/Alert';
import BackButton from '../../components/BackButton';
import { MAX_SLOTS_TO_SHOW } from '../../components/NoSlots/NoSlotsConstants';

interface AppointmentWithoutTopSlotsProps {
  stylesheet: Stylesheet;
  noTopSLots: NoTopSlot[];
  ticket: Ticket;
  lowAvailabilityAlert?: React.ReactNode;
  refetchNoTopSlots: () => void;
}

const AppointmentWithoutTopSlots: FC<AppointmentWithoutTopSlotsProps> = ({
  noTopSLots, ticket, stylesheet, lowAvailabilityAlert, refetchNoTopSlots
}) => {
  const {
    appointmentSection: { noSlotsBlock }
  } = stylesheet;
  const { formattedText: noSlotsText } = useFormatting(noSlotsBlock.value);

  const { t } = useTranslation();

  const hasNoTopSlots: boolean = !!noTopSLots.length;

  const showScrollingAlert = noTopSLots.length > MAX_SLOTS_TO_SHOW;

  return (
    <>
      <div className={styles.content}>
        <BackButton text={t('appointment:goBack')} />
      </div>
      <h1 className={styles.h1}>{t('noslots:title')}</h1>
      <div className={styles.content}>
        <p role="note" dangerouslySetInnerHTML={{ __html: noSlotsText }} />
      </div>
      {lowAvailabilityAlert}

      {(showScrollingAlert || !hasNoTopSlots) && (
        <Alert
          level={!hasNoTopSlots ? 'error' : 'info'}
          text={!hasNoTopSlots ? t('noslots:alertError') : t('noslots:alertInfo')}
          textButton={!hasNoTopSlots ? { text: t('noslots:alertButton'), onClick: refetchNoTopSlots } : undefined}
          white
        />
      )}
      {hasNoTopSlots && (
        <div className={styles.noSlots}>
          <NoSlotForm
            noTopSlots={noTopSLots}
            hashId={ticket.hashId}
            workorderId={ticket.workOrder.id}
          />
        </div>
      )}
    </>
  );
};

export default AppointmentWithoutTopSlots;
