import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { StepsUpdateContext } from '../../components/Steps/StepsContext';
import { sendRescheduleClicked } from '../../services/Analytics';
import Button from '../../components/Button';

import styles from './ConfirmReschedule.module.scss';
import StepsService from '../../services/StepsService';

interface ConfirmRescheduleProps {
  cancelingEnabled: boolean;
  reschedulingEnabled: boolean;
  availabilityProvided?: boolean;
  hashId: string;
}

const ConfirmReschedule: FC<ConfirmRescheduleProps> = ({
  hashId, cancelingEnabled, reschedulingEnabled, availabilityProvided,
}) => {
  const { navigateToPrevStep } = React.useContext(StepsUpdateContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onReschedule = () => {
    sendRescheduleClicked();
    navigateToPrevStep();
  };

  const onCancel = () => {
    navigate(StepsService.getStepUri(hashId, 'cancel'));
  };

  const changeButtonClass = classNames(styles.changeAppointmentButtonsContainer, {
    [styles.alignRight]: !reschedulingEnabled && cancelingEnabled,
  });

  return (
    <section className={styles.rescheduleContianer}>
      <h3 className={styles.rescheduleTitle}>{t('confirmSection:changeAppointment')}</h3>
      <p className={styles.rescheduleDescription}>{t('confirmSection:changeAppointmentDescription')}</p>
      <div className={changeButtonClass}>
        {reschedulingEnabled && (
          <Button
            text={availabilityProvided ? t('confirmSection:changeAvailability') : t('confirmSection:change')}
            ariaLabel={t('confirmSection:change')}
            onClick={onReschedule}
            variant="outline"
            size="small"
          />
        )}
        {cancelingEnabled && (
          <Button
            variant="text"
            size="small"
            text={t('confirmSection:cancel')}
            ariaLabel={t('confirmSection:cancel')}
            onClick={onCancel}
            className={styles.cancelAppointmentButton}
          />
        )}
      </div>
    </section>
  );
};

export default ConfirmReschedule;
