import { MouseEvent, RefObject } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';
import { FCWithOptionalChild } from '../../interfaces/Shared';
import MaterialIcon, { IconNames } from '../MaterialIcon/MaterialIcon';

export interface ButtonProps {
  icon?: { name: IconNames; direction?: 'left' | 'right' };
  text?: string;
  img?: JSX.Element;
  disabled?: boolean;
  size?: 'default' | 'small' | 'full';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  variant?: 'text' | 'contained' | 'outline';
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  ariaLabel?: string;
}

const Button: FCWithOptionalChild<ButtonProps> = ({
  buttonRef, img, icon, children, text, disabled, type = 'button', className, onClick, ariaLabel, variant = 'contained',
  size = 'default'
}) => {
  const buttonClassNames = classNames(styles.btn, className, {
    [styles.btnText]: variant === 'text',
    [styles.btnContained]: variant === 'contained',
    [styles.btnOutline]: variant === 'outline',
    [styles.btnDisabled]: disabled,
    [styles.btnSmall]: size === 'small',
    [styles.btnFull]: size === 'full',
    [styles.btnWithText]: text,
    [styles.btnIconOnly]: !text && (icon),
    [styles.reverseItems]: icon?.direction === 'right',
  });

  const iconClassNames = classNames({
    [styles.disabledColor]: disabled,
  });

  return (
    <button
      ref={buttonRef}
      className={buttonClassNames}
      onClick={(event) => !disabled && onClick ? onClick(event) : undefined}
      type={type}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {icon && <MaterialIcon name={icon.name} className={iconClassNames} />}
      {img}
      {text && <span className={styles.btnTextSpan}>{text}</span>}
      {children}
    </button>
  );
};

export default Button;
