import { FC } from 'react';
import Card from '../../../components/Card/Card';
import CardItem from '../../../components/Card/CardItem';
import { IconNames } from '../../../components/MaterialIcon/MaterialIcon';
import style from './AboutAppointment.module.scss';
import { Separator } from '../../../components/Separator/Separator';

export interface AboutAppointmentContent {
  title?: string;
  icon: IconNames;
  iconColor?: string;
  description: string;
}

export interface AboutAppointmentProps {
  title: string;
  content: AboutAppointmentContent[];
}

export const AboutAppointment: FC<AboutAppointmentProps> = ({ title, content }) => {
  return (
    <section className={style.aboutAppointment}>
      <h2 className={style.title}>{title}</h2>
      {content.map(({ description, icon, title, iconColor }, index) => (
        <Card key={description} className={style.card}>
          <CardItem icon={icon} className={style.item} iconColor={iconColor}>
            <div className={style.itemContainer}>
              {index === 0 ? <h3>{title}</h3> : null}
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </CardItem>
          {index < content.length - 1 && <Separator />}
        </Card>
      ))}
    </section>
  );
};
