import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppRoot } from './App';
import './helpers/i18n';
import { saveInitialSource } from './services/Analytics';

saveInitialSource(document.location.search);

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppRoot />
  </React.StrictMode>,
);
