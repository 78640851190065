import Axios from 'axios';

import { BackendRepository } from './interfaces/BackendRepository';
import { RemoteTicket } from '../interfaces/Ticket';
import { Technician } from '../interfaces/Technician';
import { Customer } from '../interfaces/Customer';
import { GroupedSlot } from '../interfaces/TopSlot';
import { SaveNoSlotParams } from '../interfaces/AvailableNoSlots';
import { ContactQuestionParams } from '../interfaces/Contact';
import { Signature, SignatureImageUploadParams, SignatureUpdateParams } from '../interfaces/Signature';
import { BusinessReviews } from '../interfaces/BusinessReviews';
import { NoTopSlot } from '../interfaces/NoTopSlot';

export default class BackendRepositoryRemote implements BackendRepository {
  private url: string;

  constructor() {
    if (!import.meta.env.VITE_API_BASEURL) {
      throw new Error('API_BASEURL not set');
    }
    this.url = import.meta.env.VITE_API_BASEURL;
  }

  public getTicket(ticketHashId: string): Promise<RemoteTicket> {
    return Axios.get<RemoteTicket>(`${this.url}/${ticketHashId}/ticket`)
      .then((result) => result.data);
  }

  public getBusinessReviews(): Promise<BusinessReviews> {
    return Axios.get<BusinessReviews>(`${this.url}/reviews`).then((result) => result.data);
  }

  public updateCustomer(ticketHashId: string, customer: Customer): Promise<Customer> {
    return Axios.put<Customer>(`${this.url}/${ticketHashId}/customer/${customer.id}`, customer)
      .then((result) => result.data);
  }

  public getTopSlots(ticketHashId: string, serviceAppointmentId: string, startDateTime: string, endDateTime: string)
    : Promise<GroupedSlot[]> {
    const url = `${this.url}/v2/${ticketHashId}/appointment/${serviceAppointmentId}/topslots?grouped=true`;

    const body = {
      startDate: startDateTime,
      endDate: endDateTime
    };

    return Axios.post<GroupedSlot[]>(url, body)
      .then((response) => response.data);
  }

  public getNoTopSlots(ticketHashId: string, workorderId: string): Promise<NoTopSlot[]> {
    return Axios.get<NoTopSlot[]>(`${this.url}/${ticketHashId}/workorder/${workorderId}/no-topslots`)
      .then((response) => response.data);
  }

  public bookSlot(ticketHashId: string, serviceAppointmentId: string, startDateTime: string, endDateTime: string): Promise<void> {
    const body = {
      start: startDateTime,
      end: endDateTime
    };

    return Axios.post(`${this.url}/v2/${ticketHashId}/appointment/${serviceAppointmentId}/schedule`, body);
  }

  public cancelTicket(serviceAppointmentId: string): Promise<void> {
    return Axios.post(`${this.url}/appointment/${serviceAppointmentId}/cancel`);
  }

  public completeQuestionnaire(serviceAppointmentId: string): Promise<void> {
    return Axios.post(`${this.url}/appointment/${serviceAppointmentId}/questionnaire`);
  }

  public saveNoSlotInformation(ticketHash: string, workorderId: string, params: SaveNoSlotParams): Promise<void> {
    if (params.version === 'new') {
      return Axios.post(`${this.url}/${ticketHash}/workorder/${workorderId}/no-topslots`, params.noSlots);
    }

    // version property is being remove from params
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { version, ...newParams } = params;

    return Axios.put(`${this.url}/${ticketHash}/workorder/${workorderId}/no-topslots`, newParams);
  }

  public sendContactQuestion(ticketHash: string, params: ContactQuestionParams): Promise<void> {
    return Axios.post(`${this.url}/${ticketHash}/question`, params);
  }

  public getTechnician(ticketHashId: string): Promise<Technician> {
    return Axios.get(`${this.url}/${ticketHashId}/visitcard`)
      .then((result) => result.data);
  }

  public async getSignature(ticketHashId: string): Promise<Signature> {
    return Axios.get<Signature>(`${this.url}/${ticketHashId}/signature`)
      .then((result) => result.data);
  }

  public async updateSignature({ completed, customerSigned, hashId }: SignatureUpdateParams): Promise<void> {
    return Axios.patch(`${this.url}/${hashId}/signature`, { completed, customerSigned });
  }

  public async uploadImage({ presignedUploadUrl, body }: SignatureImageUploadParams): Promise<void> {
    await Axios.post(presignedUploadUrl, body);
  }
}
