import { BackendRepository } from '../api/interfaces/BackendRepository';
import { RemoteTicket, Ticket } from '../interfaces/Ticket';
import { Customer } from '../interfaces/Customer';
import BackendRepositoryRemote from '../api/BackendRepositoryRemote';
import { SaveNoSlotParams } from '../interfaces/AvailableNoSlots';
import { ContactQuestionParams } from '../interfaces/Contact';

export default class TicketService {
  private repository: BackendRepository;

  constructor() {
    this.repository = new BackendRepositoryRemote();
  }

  public async getTicket(ticketHashId: string): Promise<Ticket> {
    const remoteTicket = await this.repository.getTicket(ticketHashId);

    return this.convertRemoteTicketToTicket(remoteTicket);
  }

  public cancelTicket(serviceAppointmentId: string): Promise<void> {
    return this.repository.cancelTicket(serviceAppointmentId);
  }

  public updateCustomer(ticketHashId: string, customer: Customer): Promise<Customer> {
    return this.repository.updateCustomer(ticketHashId, customer);
  }

  public saveNoSlotInformation(ticketHash: string, workorderId: string, params: SaveNoSlotParams): Promise<void> {
    return this.repository.saveNoSlotInformation(ticketHash, workorderId, params);
  }

  public sendContactQuestion(ticketHash: string, params: ContactQuestionParams): Promise<void> {
    return this.repository.sendContactQuestion(ticketHash, params);
  }

  private convertRemoteTicketToTicket(remoteTicket: RemoteTicket): Ticket {
    const workOrder = {
      ...remoteTicket.workOrders[0],
      appointments: undefined,
      appointment: remoteTicket.workOrders[0].appointments[0]
    };

    const ticket = {
      ...remoteTicket,
      workOrders: undefined,
      workOrder: workOrder
    };

    return ticket;
  }
}
