import { FC, useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import classNames from 'classnames';

import EcoRoutes from './Eco Routes.json';
import PickingExperts from './Picking Experts.json';
import LoadingQuestions from './Loading Questions.json';
import QuestionsSaved from './Questions Saved.json';
import SlotBooking from './Slot Booking.json';
import WinterBanner from './Winter Banner.json';

export type AnimationName = 'partners' | 'routes' | 'loadingQuestions' | 'questionsSaved' | 'slotBooking' | 'winterBanner';

const squareAnimations: Record<AnimationName, unknown> = {
  partners: PickingExperts,
  routes: EcoRoutes,
  loadingQuestions: LoadingQuestions,
  questionsSaved: QuestionsSaved,
  slotBooking: SlotBooking,
  winterBanner: WinterBanner,
};

interface AnimatedImageProps {
  name: AnimationName;
  className: string;
}

const AnimatedImage: FC<AnimatedImageProps> = ({ name, className }) => {
  const container = useRef<HTMLDivElement>(null);
  const animationLoaded = useRef<boolean>(false);

  useEffect(() => {
    const element = container.current;

    if (!element || animationLoaded.current) {
      return;
    }

    Lottie.loadAnimation({
      container: element,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: squareAnimations[name]
    });

    animationLoaded.current = true;
  }, [name]);

  const animationStyles = classNames(className);

  return (
    <div className={animationStyles} ref={container}></div>
  );
};

export default AnimatedImage;
