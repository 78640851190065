import { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { handleKeyboardSelect } from '../../helpers/Accessibility';
import styles from './NoSlotButton.module.scss';

interface NoSlotButtonProps {
  text: string;
  disabled?: boolean;
  selected?: boolean;
  onSelect: (selected: boolean) => void;
  className?: string;
}

export const NoSlotButton: FC<NoSlotButtonProps> = ({ text, disabled, onSelect, selected }) => {

  const handleSelect = useCallback(() => {
    if (!disabled) {
      onSelect(!selected);
    }
  }, [onSelect, disabled, selected]);

  const handleKeyDown = useMemo(() => handleKeyboardSelect(handleSelect), [handleSelect]);

  return (
    <div className={styles.buttonWrapper}>
      <button
        className={classNames(
          styles.slot,
          selected && 'partner-initial-border-color-rounded',
          disabled && styles.disabled
        )}
        onClick={handleSelect}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        aria-current={selected}
        disabled={disabled}
      >
        <div className={classNames(styles.slotWrapper)}>
          <div className={styles.slotInfo}>
            <div className={classNames(
              styles.slotText,
              selected && styles.selected)}>{text}</div>
          </div>
        </div>
      </button>
    </div>
  );
};
