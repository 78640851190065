export default {
  nl: {
    question: {
      goBack: 'Gegevens',
      loadingQuestions: 'De vragen worden geladen ...',
      savingAnswers: 'De antwoorden worden opgeslagen ...',
    }
  },
  en: {
    question: {
      goBack: 'Details',
      loadingQuestions: 'Loading questions ...',
      savingAnswers: 'Saving answers...',
    }
  }
};
