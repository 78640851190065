import { createRef, FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './LanguageSelector.module.scss';
import { EnabledLanguage } from '../../interfaces/Language';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import LanguageSelectorOption from './LanguageSelectorOption';
import Backdrop from '../Backdrop/Backdrop';

import Language from '../../helpers/Language';
import { sendAnalyticsLanguageSwitch } from '../../services/Analytics';
import { handleKeyboardSelect, useDocumentKeyDown } from '../../helpers/Accessibility';

interface LanguageOption {
  label: string;
  icon: string;
  id: EnabledLanguage;
  alt: string;
}

interface LanguageSelectorProps {
  placement: 'footer-mobile' | 'header-desktop';
  className?: string;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({ placement, className }) => {
  const { changeStylesheetLanguage } = useContext(StylesheetContext);
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const languageOptions: { nl: LanguageOption; en: LanguageOption } = useMemo(() => ({
    nl: { label: 'Nederlands', icon: '/assets/nlFlag.svg', id: 'nl', alt: t('headerAlts:nlFlag') },
    en: { label: 'English', icon: '/assets/ukFlag.svg', id: 'en', alt: t('headerAlts:ukFlag') },
  }), [t]);

  const numberOfOptions = Object.keys(languageOptions).length;

  const optionRefs = useMemo(() => Array(numberOfOptions).fill(0).map(() => createRef<HTMLLIElement>()), [numberOfOptions]);

  const currentLanguageId: EnabledLanguage = Language.getLanguage();
  const currentLanguage: LanguageOption = languageOptions[currentLanguageId];

  const languageSelectorClasses = classNames(className, style.languageSelector, {
    [style['languageSelector--footerMobile']]: placement === 'footer-mobile',
    [style['languageSelector--headerDesktop']]: placement === 'header-desktop',
  });

  const hideOptions = useCallback(() => {
    setVisible(false);
    setTouched(true);
  }, []);

  const focus = useCallback(() => {
    menuRef.current?.focus();
  }, [menuRef]);

  const hideOptionsAndFocus = useCallback(() => {
    hideOptions();
    focus();
  }, [hideOptions, focus]);

  useDocumentKeyDown({
    key: 'Escape',
    enabled: visible,
    handler: hideOptionsAndFocus,
  });

  const toggleOptions = useCallback(() => {
    setVisible(!visible);
    setTouched(true);
  }, [visible]);

  const handleChangeLanguage = (target: EnabledLanguage, keyboardSelect: boolean) => {
    Language.setLanguage(target);
    changeStylesheetLanguage(target);
    sendAnalyticsLanguageSwitch(target);

    if (keyboardSelect) {
      focus();
    }

    hideOptions();
  };

  const optionsWrapperClasses = classNames(style.selectorOptionsWrapper, {
    [style['selectorOptionsWrapper--visible']]: visible,
    [style['selectorOptionsWrapper--fadeout']]: touched && !visible
  });

  const handleKeyDown = useMemo(() => handleKeyboardSelect(toggleOptions), [toggleOptions]);

  return (
    <div className={languageSelectorClasses}>
      <Backdrop
        visible={visible}
        onClick={hideOptions}
      />
      <div
        ref={menuRef}
        className={style.currentSelected}
        onClick={toggleOptions}
        onKeyDown={handleKeyDown}
        role="button"
        data-test-id="language-selector-button"
        aria-label={t('language:selector')}
        tabIndex={0}>
        <img src={currentLanguage.icon} alt={currentLanguage.alt} />
        <i
          className={classNames('material-icons', {
            [style.flippedIcon]: visible
          })}
        >
          expand_more
        </i>
      </div>
      <div className={optionsWrapperClasses}>
        <p className={style.languageOptionsTitle}>Language</p>
        <ul role="menu">
          {Object.values(languageOptions).map((languageOption, index) => (
            <LanguageSelectorOption
              key={languageOption.id}
              index={index}
              optionRefs={optionRefs}
              icon={languageOption.icon}
              label={languageOption.label}
              alt={languageOption.alt}
              onClick={(keyboardSelect) => handleChangeLanguage(languageOption.id, keyboardSelect)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LanguageSelector;
