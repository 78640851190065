import * as Sentry from '@sentry/react';

type BeforeSendType = Parameters<typeof Sentry.init>[0]['beforeSend'];
export const beforeSend: Exclude<BeforeSendType, undefined> = (event) => {
  if (event.exception?.values?.length) {
    const firstException = event.exception.values[0];

    // we don't want to spam network errors,
    // they are already recorded by GA
    if (firstException.value?.indexOf('Request failed with status code') === 0) {
      return null;
    }
  }

  return event;
};

export const initializeSentryTags = (hashId: string) => {
  Sentry.setTag('rssHashId', hashId);
  Sentry.setUser({ id: hashId });
};
