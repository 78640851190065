import { FC } from 'react';

import style from './Separator.module.scss';
import classNames from 'classnames';

interface SeparatorTypes {
  classes?: string;
}

export const Separator: FC<SeparatorTypes> = ({ classes }) => (
  <div className={classNames(style.separator, classes)}></div>
);
