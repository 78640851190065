import { FC } from 'react';
import classNames from 'classnames';

import { ActionContainer } from '../ActionContainer/ActionContainer';
import style from './FAQPhoneContainer.module.scss';
import { isSFChatAvailable } from '../../services/ChatService';
import { FAQComponent } from './FAQComponent';

export const FAQPhoneContainer: FC = () => {
  const fullRowWidth = !isSFChatAvailable();

  return (
    <>
      <ActionContainer
        bgClassName={classNames({
          [style.root]: true,
          [style.noBorderMobile]: true,
          [style.noBorder]: fullRowWidth,
        })}
        innerStyle={classNames({
          [style.inner]: true,
          [style.noPaddingWide]: fullRowWidth,
          [style.noPaddingMobile]: true,
        })}>
        <FAQComponent/>
      </ActionContainer>
    </>
  );
};
