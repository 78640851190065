import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './LoadingImages.module.scss';
import SquareAnimatedImage from '../SquareAnimatedImage/SquareAnimatedImage';
import { AnimationName } from '../AnimatedImage/AnimatedImage';

export interface ImageItem {
  image: AnimationName;
  text: string;
  description?: string;
  ariaLabel: string;
}

interface LoadingImagesProps {
  items: ImageItem[];
}

const STARTING_INDEX: number = 0;

const LoadingImagesComponent: FC<LoadingImagesProps> = ({ items }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(STARTING_INDEX);

  useEffect(() => {
    if (!items[currentImageIndex] || !items.length){
      return;
    }

    const timeout = setTimeout(() => {
      const nextIndex = currentImageIndex >= (items.length - 1) ? 0 : (currentImageIndex + 1);

      if (nextIndex !== currentImageIndex) {
        setCurrentImageIndex(nextIndex);
      }
    }, 5000);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [currentImageIndex, items]);

  if (!items[currentImageIndex]) {
    return null;
  }

  const containerClassNames = classNames([
    styles.loadingImageContainer,
    items.length > 1 && styles['loadingImageContainer--withFadeOut']
  ]);

  const { text, description, image, ariaLabel } = items[currentImageIndex];

  return (
    <div className={containerClassNames} key={image}>
      <SquareAnimatedImage
        image={{ type: 'animation', name: image }}
        ariaLabel={ariaLabel}
      />
      <h4>{text}</h4>
      {description && (<p>{description}</p>)}
    </div>
  );
};

export const LoadingImages = React.memo(LoadingImagesComponent);
