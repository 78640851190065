import { FC, useContext } from 'react';

import WhatsAppLink from '../WhatsAppLink/WhatsAppLink';
import ChatLink from '../ChatLink/ChatLink';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { isSFChatAvailable, isWhatsappAvailable } from '../../services/ChatService';

export const ChatContainer: FC= () => {
  const { stylesheet } = useContext(StylesheetContext);

  return (
    <>
      {isWhatsappAvailable(stylesheet) && <WhatsAppLink />}
      {isSFChatAvailable() && <ChatLink />}
    </>
  );
};
