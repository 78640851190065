import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SmallActionContainer from '../SmallActionContainer/SmallActionContainer';
import { TicketContext } from '../../contexts/TicketContext';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import StepsService from '../../services/StepsService';
import style from './SmallFAQCallContainer.module.scss';

interface SmallFAQCallContainerProps {
  renderFAQ?: boolean;
  renderPhone?: boolean;
  fullRowWidth?: boolean;
  mobileOnly?: boolean;
}

export const SmallFAQCallContainer: FC<SmallFAQCallContainerProps> = ({ renderFAQ, renderPhone, fullRowWidth, mobileOnly }) => {
  const { ticket } = useContext(TicketContext);
  const { stylesheet } = useContext(StylesheetContext);
  const { serviceDeskNumber } = stylesheet.contact;
  const { t } = useTranslation();

  const actions = useMemo(() => {
    const actions = [];

    if (renderFAQ !== false) {
      actions.push({
        icon: <MaterialIcon name="event_note" size={24} />,
        header: <Link to={StepsService.getStepUri(ticket?.hashId, 'contact')}>{t('contact:contactPageLink')}</Link>,
        content: <span>{t('contact:contactPageLinkDescription')}</span>,
        alt: t('contact:contactPageLink'),
      });
    }

    if (renderPhone !== false){
      actions.push({
        icon: <MaterialIcon className={style.callIcon} name="phone" size={24} />,
        header: <span>{t('footer:call')}</span>,
        content: <a href={`tel:${serviceDeskNumber}`}>{serviceDeskNumber} ({t('contact:localTariff')})</a>,
        alt: t('contact:localTariff'),
      });
    }

    return actions;
  }, [renderFAQ, renderPhone, serviceDeskNumber, t, ticket?.hashId]);

  return (
    <SmallActionContainer
      mobileOnly={mobileOnly}
      wide={fullRowWidth}
      actions={actions}/>
  );

};
