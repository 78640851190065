export default {
  nl: {
    cancel: {
      title: 'Weet je zeker dat je de afspraak wilt annuleren?',
      titleSuccess: 'Je afspraak is geannuleerd',
      description: 'Voor een nieuwe afspraak zul je contact op moeten nemen met de klantenservice van {{partner}}.',
      errorMessage: 'De afspraak kon helaas niet worden geannuleerd. Probeer het later opnieuw.',
      makeAppointment: 'Toch liever een afspraak maken? ',
      makeAppointmentLink: 'Klik hier om verder te gaan',
      makeNewAppointment: 'Nieuwe afspraak maken?',
      contactPartner: 'Neem contact op met {{partner}}',
    }
  },
  en: {
    cancel: {
      title: 'Are you sure you want to cancel the appointment?',
      titleSuccess: 'Your appointment has been cancelled',
      description: 'To make a new appointment, you will have to contact {{partner}} customer service.',
      errorMessage: 'Unfortunately, the appointment could not be cancelled. Try again later.',
      makeAppointment: 'Do you still want to make an appointment for your services?',
      makeAppointmentLink: 'Click here to continue',
      makeNewAppointment: 'Make a new appointment?',
      contactPartner: 'Please contact {{partner}}',
    }
  }
};
