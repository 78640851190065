import { RouteObject } from 'react-router-dom';

import {
  Appointment,
  Cancel,
  Change,
  Confirm,
  Contact,
  Customer,
  Error,
  Faq,
  Questions
} from './pages';
import { TicketOutlet } from './components/TicketOutlet/TicketOutlet';
import { initializeErrorOutlet, initializeSignatureOutlet, initializeTicketOutlet } from './services/Outlet';
import InitialPageNavigator from './components/InitialPageNavigator/InitialPageNavigator';
import SignatureComponent from './pages/Signature/Signature';
import { SignatureOutlet } from './components/SignatureOutlet/SignatureOutlet';
import { ErrorOutlet } from './components/ErrorOutlet/ErrorOutlet';
import { initTypes } from './services/RedirectService';
import { BackwardsCompatibilityRedirect, WildcardRedirect } from './pages/Redirects';


export const routes: RouteObject[] = [{
  path: 'error/:errorType',
  element: <ErrorOutlet />,
  loader: (args) => initializeErrorOutlet(args.params.errorType!),
  children: [
    { index: true, Component: Error }
  ]
}, {
  path: `${initTypes.cancel}/:hashId`,
  children: [
    { path: '*', element: <BackwardsCompatibilityRedirect initType="c" /> }
  ]
}, {
  path: `${initTypes.ticket}/:hashId`,
  children: [
    { path: '*', element: <BackwardsCompatibilityRedirect initType="t" /> }
  ]
}, {
  path: `${initTypes.signature}/:hashId`,
  children: [
    { path: '*', element: <BackwardsCompatibilityRedirect initType="sign" /> }
  ]
}, {
  path: ':hashId',
  element: <SignatureOutlet />,
  loader: (args) => initializeSignatureOutlet(args.params.hashId!),
  children: [
    { path: initTypes.signature, Component: SignatureComponent }
  ],
}, {
  path: ':hashId',
  element: <TicketOutlet />,
  loader: (args) => initializeTicketOutlet(args.params.hashId!),
  children: [
    { path: 'appointment', Component: Appointment },
    { path: 'change', Component: Change },
    { path: 'confirm', Component: Confirm },
    { path: 'contact', Component: Contact },
    { path: 'customer', Component: Customer },
    { path: 'cancel', Component: Cancel },
    { path: 'faq', Component: Faq },
    { path: 'questions', Component: Questions },
    { index: true, Component: InitialPageNavigator }
  ]
}, {
  path: '*',
  Component: WildcardRedirect
}];
