import { useTranslation } from 'react-i18next';

import ContainerHeader from '../../components/ContainerHeader/ContainerHeader';
import Navigation from '../../components/Navigation/Navigation';

import styles from './Faq.module.scss';
import { FCWithChild } from '../../interfaces/Shared';
import BackButton from '../../components/BackButton';

const FaqHeader: FCWithChild = ({ children }) => {
  const { t } = useTranslation();

  return (
    <ContainerHeader title={t('faq:title')} text={t('faq:text')} containerHeaderClassName={styles.faqHeader}>
      <Navigation className={styles.navigationContainer}>
        <BackButton useRouter text={t('button:back')}/>
      </Navigation>
      {children}
    </ContainerHeader>
  );
};

export default FaqHeader;
