import StylesheetService, { getActiveStylesheet } from './StylesheetService';
import Language from '../helpers/Language';
import { MultiLingualStylesheet, Stylesheet } from '../interfaces/Stylesheet';
import { styleSheetIsInMaintenanceMode } from './MaintenanceService';
import { calculateInitialPage, errorUrls, getInitType } from './RedirectService';
import TicketService from './TicketService';
import { setAppointmentId } from './Analytics';
import { initializeChat } from './ChatService';
import { Ticket } from '../interfaces/Ticket';
import { initializeSentryTags } from '../components/Analytics/SentryHelpers';
import { Signature } from '../interfaces/Signature';
import SignatureService from './SignatureService';

export interface TicketOutletContext {
  ticket: Ticket | undefined;
  activeStylesheet: Stylesheet;
  globalStylesheet: MultiLingualStylesheet;
  brandStylesheet: MultiLingualStylesheet | undefined;
  initialPage: string;
  isInMaintenanceMode: boolean;
}

export interface ErrorOutletContext {
  activeStylesheet: Stylesheet;
  globalStylesheet: MultiLingualStylesheet;
  errorType: string;
  isInMaintenanceMode: boolean;
}

export interface SignatureOutletContext extends TicketOutletContext {
  signature: Signature | undefined;
}

export const initializeTicketOutlet = async (hashId: string): Promise<TicketOutletContext> => {
  initializeSentryTags(hashId);

  const stylesheetService = new StylesheetService();
  const globalStylesheet = await stylesheetService.getGlobalStylesheet();

  if (styleSheetIsInMaintenanceMode(globalStylesheet)) {
    return {
      isInMaintenanceMode: true,
      initialPage: errorUrls.maintenance,
      activeStylesheet: getActiveStylesheet(globalStylesheet, undefined, Language.getLanguage()),
      brandStylesheet: undefined,
      globalStylesheet,
      ticket: undefined,
    };
  }

  const ticketService = new TicketService();
  const ticket = await ticketService.getTicket(hashId).catch(() => undefined);
  const initType = getInitType();
  const brandStylesheet = ticket
    ? await stylesheetService.getStylesheet(ticket.workOrder.workTypeId).catch(() => undefined)
    : undefined;

  setAppointmentId(ticket?.workOrder.appointment.id);

  if (ticket && brandStylesheet) {
    initializeChat(ticket, brandStylesheet[Language.getLanguage()]);
  }

  const activeStylesheet = getActiveStylesheet(globalStylesheet, brandStylesheet, Language.getLanguage());

  const initialPage = calculateInitialPage({ ticket, stylesheet: activeStylesheet, initType });

  return {
    ticket,
    activeStylesheet,
    globalStylesheet,
    brandStylesheet,
    initialPage,
    isInMaintenanceMode: false,
  };
};


export const initializeSignatureOutlet = async (hashId: string): Promise<SignatureOutletContext> => {
  const [
    ticketOutletContext,
    signature
  ] = await Promise.all([
    initializeTicketOutlet(hashId),
    new SignatureService().getSignature(hashId).catch(() => undefined)
  ]);

  return {
    ...ticketOutletContext,
    signature,
  };
};

export const initializeErrorOutlet = async (errorType: string): Promise<ErrorOutletContext> => {
  const stylesheetService = new StylesheetService();
  const globalStylesheet = await stylesheetService.getGlobalStylesheet();

  const isInMaintenanceMode = styleSheetIsInMaintenanceMode(globalStylesheet);

  return {
    globalStylesheet,
    activeStylesheet: getActiveStylesheet(globalStylesheet, undefined, Language.getLanguage()),
    errorType,
    isInMaintenanceMode
  };
};
