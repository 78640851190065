import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Ticket } from '../../interfaces/Ticket';
import { Stylesheet } from '../../interfaces/Stylesheet';
import { TopSlot } from '../../interfaces/TopSlot';

import Alert from '../../components/Alert/Alert';

import { useFormatting } from '../../services/hooks/useFormatting';

import styles from './Appointment.module.scss';

interface AppointmentConfirmationTextProps {
  ticket: Ticket;
  stylesheet: Stylesheet;
  selectedTopSlot: TopSlot;
}

const AppointmentConfirmationText: FC<AppointmentConfirmationTextProps> = ({ ticket, stylesheet, selectedTopSlot }) => {
  const { pathname } = useLocation();
  const { appointmentSection } = stylesheet;
  const { workOrder: { appointment } } = ticket;

  const isMigrationTicket = ticket.isMigration
    && (new Date(selectedTopSlot.start) > new Date(appointment.firstPossiblePlanDate)
      || appointmentSection.migrationWarningShowOnPageLoad);
  const isRescheduling = pathname === '/change';
  const hasWarningText = appointmentSection.migrationWarningText &&
    (!isRescheduling || (isRescheduling && !appointmentSection.migrationWarningChangeText));

  const { t } = useTranslation();

  const { formattedText: choiceText } = useFormatting(appointmentSection.choiceTextBlock.value);
  const { formattedText: choiceDescriptionText } = useFormatting(appointmentSection.choiceDescriptionText);
  const { formattedText: slotText } = useFormatting(
    t('slots:yourChoice'),
    {
      replaceDate: selectedTopSlot.start,
      range: { start: selectedTopSlot.start, finish: selectedTopSlot.end },
    });
  const { formattedText: migrationWarningText } = useFormatting(
    appointmentSection.migrationWarningText, { replaceDate: appointment.firstPossiblePlanDate });
  const { formattedText: migrationWarningChangeText } = useFormatting(
    appointmentSection.migrationWarningChangeText, { replaceDate: appointment.firstPossiblePlanDate, });

  return (
    <>
      <h2 className={classNames(styles.appointmentConfirmationHeader, 'primary-color')} dangerouslySetInnerHTML={{
        __html: choiceText
      }}></h2>
      <p role="note" dangerouslySetInnerHTML={{ __html: slotText }}></p>
      {!!isMigrationTicket && !!hasWarningText && (
        <Alert
          white
          text={migrationWarningText}
          level="warn"
        />
      )}
      {!!isMigrationTicket && !!isRescheduling && !!appointmentSection.migrationWarningChangeText && (
        <Alert
          white
          text={migrationWarningChangeText}
          level="warn"
        />
      )}
      <p role="contentinfo" dangerouslySetInnerHTML={{ __html: choiceDescriptionText }}></p>
    </>
  );
};

export default AppointmentConfirmationText;
