import { SalesforceQuestionnaireMessage } from '../interfaces/Message';

// complex logic due to also browser extention messages interfering and such
export const isSalesforceMessage = (data: unknown): data is SalesforceQuestionnaireMessage => {
  return typeof data === 'object'
    && data !== null
    && 'source' in data
    && 'payload' in data
    && data.source === 'salesforce-questionnaire';
};
