import { FC, useCallback, useContext, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Customer as CustomerType } from '../../interfaces/Customer';
import { Ticket } from '../../interfaces/Ticket';
import { SaveStatus } from './interfaces/Customer';
import style from './Customer.module.scss';

import { TicketContext } from '../../contexts/TicketContext';
import CustomerHeader from './CustomerHeader';
import PersonData from '../../components/PersonData/PersonData';
import AddressData from '../../components/AddressData/AddressData';
import AcceptData from './AcceptData/AcceptData';
import { Separator } from '../../components/Separator/Separator';

import { sendSubmitPersonalDetailsClicked } from '../../services/Analytics';
import CustomerController from './CustomerController';
import usePageTitle from '../../services/hooks/usePageTitle';
import { isTicketCancellable } from '../../helpers/TicketHelpers';
import Alert from '../../components/Alert/Alert';
import { TicketOutletContext } from '../../services/Outlet';
import StepsService from '../../services/StepsService';

const initialSaveStatus: SaveStatus = { success: false, error: false };

const Customer: FC = () => {
  const { ticket, activeStylesheet } = useOutletContext<TicketOutletContext>();
  const { setTicket, customer, setCustomer } = useContext(TicketContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>({ ...initialSaveStatus });
  const [fieldErrors, setFieldErrors] = useState(CustomerController.getInitialFieldErrors());

  const navigate = useNavigate();

  const { t } = useTranslation();
  const classNamesDetails = classnames('row', style.details);

  usePageTitle(t('pageTitle:customer'));

  const onFieldChange = useCallback(<T extends keyof CustomerType, >(value: CustomerType[T], fieldName: T ): void => {
    if (fieldErrors[fieldName]) {
      fieldErrors[fieldName] = undefined;
    }

    CustomerController.onFieldChange({ value, fieldName, customer, setCustomer });
  }, [customer, setCustomer, fieldErrors]);

  const onSave = useCallback(async (ticket: Ticket): Promise<boolean> => {
    const isValid = CustomerController.validateFields(customer, setFieldErrors, t);

    if (!isValid) {
      return false;
    }

    sendSubmitPersonalDetailsClicked();

    if (isEqual(customer, ticket.customer)) {
      return true;
    }

    const isSuccessful = await CustomerController.onSave({
      setIsLoading,
      setTicket,
      setSaveStatus,
      customer,
      ticket,
      initialSaveStatus: { ...initialSaveStatus },
    });

    return isSuccessful;
  }, [setTicket, customer, t]);

  if (!ticket) {
    // Should never happen, PageGuard will navigate to error page
    return null;
  }

  const canCancelTicket = isTicketCancellable(ticket, activeStylesheet);

  return (
    <div>
      <CustomerHeader stylesheet={activeStylesheet} allowCancelling={canCancelTicket} />

      <div className={classNamesDetails}>
        {!!customer && (
          <PersonData
            stylesheet={activeStylesheet}
            customer={customer}
            onFieldChange={onFieldChange}
            isLoading={isLoading}
            saveStatus={saveStatus}
            fieldErrors={fieldErrors}
          />
        )}
        {!!customer && (
          <AddressData
            stylesheet={activeStylesheet}
            customer={customer}
            allowCancelling={canCancelTicket}
            hashId={ticket.hashId}
          />
        )}
      </div>

      <AcceptData
        onSave={() => onSave(ticket)}
        isLoading={isLoading}
      />

      {canCancelTicket && (
        <div className={style.mobileOnly}>
          <Alert
            text={t('alert:cancelTicket')}
            className={style.cancellation}
            level="info"
            textButton={{
              text: t('alert:clickToCancel'),
              onClick: () => navigate(StepsService.getStepUri(ticket.hashId, 'cancel')),
            }}
          />
        </div>
      )}

      <Separator classes={classNames(style.separator)}/>
    </div>
  );
};

export default Customer;
