import { FC } from 'react';
import { Widget } from '@typeform/embed-react';
import styles from './FeedbackWidget.module.scss';

interface FeedbackWidgetProps {
  feedbackQuestionsId?: string;
  serviceAppointmentId?: string;
}

const FeedbackWidget: FC<FeedbackWidgetProps> = ({ feedbackQuestionsId, serviceAppointmentId }) => {
  if (!feedbackQuestionsId || !serviceAppointmentId) {
    return null;
  }

  return (
    <Widget
      id={feedbackQuestionsId}
      className={styles.widget}
      typeform-welcome={0}
      inlineOnMobile
      hidden={{
        service_appointment_id: serviceAppointmentId
      }}
    />
  );
};

export default FeedbackWidget;
