import { FC, useContext } from 'react';
import { Trans } from 'react-i18next';

import styles from './BusinessReviews.module.scss';
import StarRating from '../../pages/Confirm/ExpertCard/StarRating';
import { BusinessReviewsContext } from '../../contexts/BusinessReviewsContext';


const BusinessReviews: FC = () => {
  const { businessReviews } = useContext(BusinessReviewsContext);

  if (!businessReviews) {
    return null;
  }

  const { starRating, reviewCount } = businessReviews;

  return (
    <div className={styles.container}>
      <div className={styles.starRating}>
        <span>{starRating}</span>
        <StarRating value={Number(starRating)} starBackgroundColor="#F5B81A33" starColor="#FAA540" isMobile />
      </div>
      <div className={styles.reviewsWrapper}>
        <a
          href="https://www.google.com/search?q=Guidion&rlz=1C5CHFA_enNL982NL982&oq=guidion+&aqs=chrome.0.69i59j69i60l5j69i65l2.2955j0j7&sourceid=chrome&ie=UTF-8#lrd=0x416539b0ebdddea3:0xf58d721088c2d47e,1,,,"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Trans i18nKey="footer:businessReviews" values={{ value: reviewCount }}></Trans>
        </a>
      </div>
    </div>
  );
};

export default BusinessReviews;
