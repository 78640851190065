import React, { useCallback, useEffect } from 'react';

import { SlotsDictionary, TopSlot } from '../../interfaces/TopSlot';
import { NoTopSlot } from '../../interfaces/NoTopSlot';
import { TicketContext } from '../../contexts/TicketContext';
import { usePost } from '../../helpers/hooks/usePost';

import TopSlotService, { GetTopSlotsResult } from '../../services/TopSlotService';
import { sendAnalyticsNoSlotsWithError, sendAnalyticsSlotsReceived } from '../../services/Analytics';
import DateTime from '../../helpers/DateTime';
import NoTopSlotService from '../../services/NoTopSlotService';

export interface UseTopSlotsResult {
  isLoading: boolean;
  isNoSlotsLoading: boolean;
  initialRequestCompleted: boolean;
  topSlots: SlotsDictionary;
  noTopSlots: NoTopSlot[];
  highestSlot: TopSlot;
  availableDateStart: Date;
  availableDateEnd: Date;
  refetchNoTopSlots: () => void;
}

const EMPTY_GRADE = -1;

export const useTopSlots = (minDate: Date, maxDate: Date): UseTopSlotsResult => {
  const { ticket } = React.useContext(TicketContext);
  const [noTopSlots, setNoTopSlots] = React.useState<NoTopSlot[]>([]);
  const [noTopSlotsLoading, setNoTopSlotsLoading] = React.useState<boolean>(false);
  const { response: { complete, data }, doRequest } = usePost<GetTopSlotsResult>();
  const [topSlots, setTopSlots] = React.useState<SlotsDictionary>({});

  // these 2 parameters store a timeframe for data that was requested from the server
  const [availableDateStart] = React.useState(DateTime.cloneDate(minDate));
  const [availableDateEnd, setAvailableDateEnd] = React.useState(DateTime.cloneDate(maxDate));

  const [highestSlot, setHighestSlot] = React.useState<TopSlot>({ start: '', end: '', grade: EMPTY_GRADE });
  const [initialRequestCompleted, setInitialRequestCompleted] = React.useState(false);
  const [getTopslotsPromise, setGetTopSlotsPromise] = React.useState<Promise<GetTopSlotsResult> | undefined>();

  const getNoTopSlots = useCallback(async (): Promise<void> => {
    if (ticket) {
      try {
        setNoTopSlotsLoading(true);
        const response = await new NoTopSlotService().getNoTopSlots(ticket.hashId, ticket.workOrder.id);
        const filteredNoTopSlots = response.noTopSlots && response.noTopSlots.length
          ? response.noTopSlots.filter(noTopSlot => !!noTopSlot.periods.length)
          : [];
        setNoTopSlots(filteredNoTopSlots);
      } finally {
        setNoTopSlotsLoading(false);
      }
    }
  }, [ticket]);

  const refetchNoTopSlots = useCallback(() => {
    getNoTopSlots();
  }, [getNoTopSlots]);

  useEffect(() => {
    if (ticket) {
      const getTopSlotsPromise = new TopSlotService().getTopSlots(
        ticket.hashId,
        ticket.workOrder.appointment.id,
        minDate,
        maxDate
      );

      setGetTopSlotsPromise(getTopSlotsPromise);
    }
  }, [minDate, maxDate, ticket]);

  useEffect(() => {
    if (getTopslotsPromise) {
      doRequest(getTopslotsPromise);
    }
  }, [doRequest, getTopslotsPromise]);

  useEffect(() => {
    if (!data || !complete) {
      return;
    }

    const getTopSlotsResult = data as GetTopSlotsResult;

    let highest = EMPTY_GRADE;
    let newBestSlot: TopSlot | null = null;

    sendAnalyticsSlotsReceived(getTopSlotsResult.slots);

    const newTopSlots = getTopSlotsResult.slots.reduce((slots, s) => {
      const timeslots = s.timeslots;

      const newHighest = s.timeslots?.reduce((prev, current) => {
        return prev.grade >= current.grade ? prev : current;
      });

      if (timeslots) {
        slots[s.date] = timeslots;
      }

      if (newHighest) {
        if (highest < newHighest.grade) {
          highest = newHighest.grade;
          newBestSlot = newHighest;
        }
      }

      return slots;
    }, {} as SlotsDictionary);

    setInitialRequestCompleted(true);
    setAvailableDateEnd(getTopSlotsResult.requestedDateEnd);
    setTopSlots(currentSlots => ({
      ...currentSlots,
      ...newTopSlots,
    }));

    if (newBestSlot) {
      setHighestSlot(newBestSlot);
    }

    if (getTopSlotsResult.slots.length === 0 && getTopSlotsResult.requestsWithError) {
      sendAnalyticsNoSlotsWithError(getTopSlotsResult.requestsWithError);
    }
  }, [data, complete]);

  useEffect(() => {
    if ((!data || !data.slots.length) && complete) {
      getNoTopSlots();
    }
  }, [data, complete, getNoTopSlots]);

  return {
    isLoading: !complete,
    isNoSlotsLoading: noTopSlotsLoading,
    initialRequestCompleted,
    topSlots,
    noTopSlots,
    highestSlot,
    availableDateStart,
    availableDateEnd,
    refetchNoTopSlots
  };
};
