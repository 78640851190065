import { FunctionComponent } from 'react';
import { t } from 'i18next';

import styles from './ExpertInfo.module.scss';
import { ExpertStar } from './ExpertStar';
import classNames from 'classnames';

interface ExpertInfoProps {
  appointments?: string;
  yearsAtGuidion?: string;
  rating?: string;
}

const ExpertInfo: FunctionComponent<ExpertInfoProps> = ({ appointments, yearsAtGuidion, rating }) => {
  return (
    <div className={styles.expertRating}>
      {!!appointments && (
        <div className={styles.expertRatingItem}>
          <span>{appointments}</span>
          <span className={styles.expertInfoLabel}>{t('visitcard:serviceVisits')}</span>
        </div>
      )}
      {!!yearsAtGuidion && (
        <div className={styles.expertRatingItem}>
          <span>{yearsAtGuidion}</span>
          <span className={styles.expertInfoLabel}>{t('visitcard:yearsAtGuidion')}</span>
        </div>
      )}
      {!!rating && (
        <div className={classNames(styles.expertRatingItem, styles.expertStarWrapper)}>
          <ExpertStar value={rating} shouldDisplayIn="desktop" />
          <span className={classNames(styles.expertInfoLabel, styles.expertRatingInfo)}>{t('visitcard:rating')}</span>
        </div>
      )}
    </div>
  );
};

export default ExpertInfo;
