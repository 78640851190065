import { FC } from 'react';

import styles from './Tag.module.scss';

interface TagProps {
  text: string;
  icon?: JSX.Element;
}

export const Tag: FC<TagProps> = ({ text, icon }): JSX.Element => {
  return (
    <div className={styles.container}>
      <span>{text}</span>
      {icon}
    </div>
  );
};
