import React, { FunctionComponent } from 'react';

import { StepsUpdateContext } from '../Steps/StepsContext';
import { useFormatting } from '../../services/hooks/useFormatting';
import { useLocation } from 'react-router-dom';
import { StepId } from '../Steps/interfaces/Step';
import Button from '../Button';
import { TicketContext } from '../../contexts/TicketContext';

interface NavigationButtonProps {
  text: string;
  nextDisabled: boolean;
  buttonAction: 'next' | 'prev';
  onClickOverride?: () => void;
  preNavigateAction?: () => Promise<boolean>;
}

const NavigationButton: FunctionComponent<NavigationButtonProps> = ({
  text, buttonAction, nextDisabled, preNavigateAction, onClickOverride
}) => {
  const { completeCurrentStep, navigateToNextStep, navigateToPrevStep } = React.useContext(StepsUpdateContext);
  const { isInErrorMode } = React.useContext(TicketContext);
  const { formattedText: buttonText } = useFormatting(text);
  const { pathname } = useLocation();

  const handleNextButtonClick = async (): Promise<void> => {
    if (onClickOverride) {
      onClickOverride();
      return;
    }

    if (preNavigateAction) {
      const isSuccesfull = await preNavigateAction();

      if (!isSuccesfull) {
        return;
      }
    }

    completeCurrentStep();
    navigateToNextStep();
  };

  const handlePrevButtonClick = (): void => {
    if (onClickOverride) {
      onClickOverride();
      return;
    }

    navigateToPrevStep();
  };

  if (isInErrorMode) {
    return null;
  }

  return (
    <>
      {buttonAction === 'prev' && pathname !== `/${StepId.change}` && (
        <Button
          variant="outline"
          text={buttonText}
          onClick={handlePrevButtonClick}
        />
      )}

      {buttonAction === 'next' && (
        <Button
          size="full"
          disabled={nextDisabled}
          text={buttonText}
          onClick={handleNextButtonClick}
        />
      )}
    </>
  );
};

export default NavigationButton;
