import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createBrowserRouter, createRoutesFromChildren, matchRoutes,
  Routes, useLocation, useNavigationType
} from 'react-router-dom';

import { beforeSend } from './SentryHelpers';


Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  ignoreErrors: [/@webkit-masked-url/],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
  ],
  beforeSend,
  tracesSampleRate: 0,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// is fine is as per documentation suggestion https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
// eslint-disable-next-line react-refresh/only-export-components
export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
