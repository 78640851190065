import { FC } from 'react';
import { Star } from './StarRating';

import style from './ExpertStar.module.scss';
import classNames from 'classnames';

interface ExpertStarProps {
  value: string;
  shouldDisplayIn: 'mobile' | 'desktop';
}

export const ExpertStar: FC<ExpertStarProps> = ({ value, shouldDisplayIn }) => {
  const wrapperClasses = classNames({
    [style.expertStar]: true,
    [style.expertStarMobile]: shouldDisplayIn === 'mobile',
    [style.expertStarDesktop]: shouldDisplayIn === 'desktop',
  });

  return (
    <div className={wrapperClasses}>
      <Star
        starColor="#FAA540"
        value={1}
        isMobile
      />
      <span>{value}</span>
    </div>
  );
};
