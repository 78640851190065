import { useEffect } from 'react';

export const useScrollToTop = (): void => {
  useEffect(() => {
    // doing it in the next tick to ensure that the scroll is done after the render
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);
};
