import { FC } from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';

import { TicketOutletContext } from '../../services/Outlet';

const InitialPageNavigator: FC = () => {
  const outletContext = useOutletContext<TicketOutletContext>();

  return <Navigate to={outletContext.initialPage} />;
};

export default InitialPageNavigator;
