export default {
  nl: {
    button: {
      nextStep: 'Volgende stap',
      prevStep: 'Vorige stap',
      saveDetails: 'Gegevens opslaan',
      back: 'Terug',
      send: 'Versturen',
      contactGuidion: 'Contact Guidion',
      faq: 'FAQ openen',
      cancelAppointment: 'Ja, annuleren',
      abortCancelAppointment: 'Nee, toch niet',
    }
  },
  en: {
    button: {
      nextStep: 'Next step',
      prevStep: 'Previous step',
      saveDetails: 'Save details',
      back: 'Back',
      send: 'Send',
      contactGuidion: 'Contact Guidion',
      faq: 'Open FAQ',
      cancelAppointment: 'Yes, cancel',
      abortCancelAppointment: 'Not yet',
    }
  }
};
