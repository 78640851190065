import { Navigate, Outlet, useLoaderData, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import FadeInOutWrapper from '../FadeInOutWrapper/FadeInOutWrapper';
import { SignatureOutletContext } from '../../services/Outlet';
import StepsService from '../../services/StepsService';
import { SupportedErrorTypes } from '../../services/ErrorService';
import { TicketProvider } from '../../contexts/TicketContext';
import { StylesheetProvider } from '../../contexts/StylesheetContext';
import { useMaintenanceGuard } from '../../helpers/hooks/useMaintenanceGuard';
import { hideSpinner } from '../../services/Spinner';

export const SignatureOutlet = () => {
  const loaderData = useLoaderData() as SignatureOutletContext;
  const { ticket, brandStylesheet, globalStylesheet, isInMaintenanceMode, signature } = loaderData;

  const { pathname } = useLocation();

  const pageClasses = classNames(
    'page',
    !StepsService.showSteps(pathname) && 'pageWithoutStepper',
  );

  hideSpinner();
  useMaintenanceGuard(isInMaintenanceMode);

  if (!ticket){
    return <Navigate to={`/error/${SupportedErrorTypes.ticketNotFound}`} />;
  }

  if (!signature){
    return <Navigate to={`/error/${SupportedErrorTypes.noSignatureFound}`} />;
  }

  return (
    <TicketProvider defaultTicket={ticket}>
      <StylesheetProvider
        brandStylesheet={brandStylesheet}
        globalStylesheet={globalStylesheet}
      >
        <>
          <Header />
          <main className={pageClasses}>
            <FadeInOutWrapper isEnabled>
              <div className="container">
                <Outlet context={loaderData} />
              </div>
            </FadeInOutWrapper>
          </main>
          <Footer />
        </>
      </StylesheetProvider>
    </TicketProvider>
  );
};
