import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import styles from './ActionContainer.module.scss';

interface ActionContainerProps {
  bgClassName?: string;
  innerStyle?: string;
  children: ReactNode;
}

export const ActionContainer: FC<ActionContainerProps> = ({ bgClassName, innerStyle, children }) => {
  return (
    <div className={classNames(styles.root, bgClassName)}>
      <div className={classNames(styles.container, innerStyle)}>
        {children}
      </div>
    </div>
  );
};
