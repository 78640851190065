import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { errorUrls } from '../../services/RedirectService';


export const useMaintenanceGuard = (isInMaintenanceMode: boolean) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isInMaintenanceMode){
      navigate(errorUrls.maintenance);
    }
  }, [isInMaintenanceMode, navigate]);
};
