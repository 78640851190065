import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './NoSlotsSummary.module.scss';
import { NoTopSlot } from '../../interfaces/NoTopSlot';
import Card from '../Card/Card';

import DateTime from '../../helpers/DateTime';

interface NoSlotButtonProps {
  selectedNoTopSlots: NoTopSlot[];
}

const NoSlotsSummary: FC<NoSlotButtonProps> = ({ selectedNoTopSlots }) => {
  const { t } = useTranslation();

  return (
    <Card className={styles.warningContainer}>
      <div className={styles.warningText}>
        <p className={styles.warningTitle}>
          {t('confirmSection:selectedNoTopSlotsContentCardTitle')}
        </p>
        <table className={styles.table}>
          {selectedNoTopSlots.map(({ day, periods }) => (
            <tr key={day} className={styles.slotRow}>
              <td>
                {DateTime.formatDate('EEEE dd LLLL', new Date(day))}
              </td>
              <td className={styles.timeslots}>
                {periods.map(({ from, to }, index) => (
                  <div key={index} className={styles.timeSlot}>
                    {`${DateTime.getTimeStringFromDate(
                      DateTime.roundToNearestMinutes(from)
                    )}
                  - ${DateTime.getTimeStringFromDate(
                    DateTime.roundToNearestMinutes(to)
                  )} uur`}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </table>

      </div>
    </Card>
  );
};

export default NoSlotsSummary;
