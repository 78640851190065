import { FC } from 'react';
import classNames from 'classnames';

import IconButton from '../IconButton/IconButton';
import style from './CloseButton.module.scss';

interface CloseButtonProps {
  alignment: 'topRight';
  onClick: () => void;
}

export const CloseButton: FC<CloseButtonProps> = ({ alignment, onClick }) => {
  return (
    <IconButton
      icon="close"
      onClick={onClick}
      className={classNames(style.closeButton, style[alignment])}
    />
  );
};
