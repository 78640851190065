export default {
  nl: {
    errorMessages: {
      canceled: 'Afspraak niet gevonden.',
      caseNotFound: 'Afspraak helaas niet gevonden.',
      signatureNotFound: 'De link is verlopen',
      signatureNotFoundParagraph: 'De monteur kan een nieuwe QR code genereren zodat je via de link de werkbon kan ondertekenen.',
      closed: 'Afspraak helaas niet gevonden.',
      appointmentNotPossible: 'Het maken van een afspraak is helaas nog niet mogelijk.',
      noAppointmentYet: 'Het maken van een afspraak is helaas nog niet mogelijk.',
      alreadyCompleted: 'De afspraak heeft al plaatsgevonden.',
      noStylesheet: 'Geen layout gevonden.',
      maintenance: 'Under construction',
      default: 'Er is iets fout gegaan.'
    },
  },
  en: {
    errorMessages: {
      canceled: 'Your appointment has not been found.',
      caseNotFound: 'No ticket found.',
      signatureNotFound: 'Link is expired',
      signatureNotFoundParagraph: 'The technician can generate a new QR code so you can sign the work order via the link.',
      closed: 'Your appointment has not been found.',
      appointmentNotPossible: 'Unfortunately you can\'t make an appointment yet.',
      noAppointmentYet: 'You can\'t make an appointment yet',
      alreadyCompleted: 'You already have had an appointment',
      noStylesheet: 'No layout found.',
      maintenance: 'Under construction',
      default: 'Something went wrong.'
    },
  }
};
