import { FC, useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import style from './Step.module.scss';
import { StepModel } from './interfaces/Step';
import { handleKeyboardSelect } from '../../helpers/Accessibility';
import { Check } from './Check';
import { TicketContext } from '../../contexts/TicketContext';
import StepsService from '../../services/StepsService';

interface StepProps {
  step: StepModel;
  index: number;
}

const CONFIRM_STEP_INDEX = 4;

const Step: FC<StepProps> = ({
  step: { id, isCompleted, isDisabled }, index
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { ticket } = useContext(TicketContext);

  const isActive = pathname.endsWith(`/${id}`);

  const stepContainerClasses = classNames({
    [style.stepContainer]: true,
    [style.stepContainerFullWidth]: index !== CONFIRM_STEP_INDEX,
  });

  const handleClick = useCallback(() => {
    const uri = StepsService.getStepUri(ticket?.hashId, id);

    navigate(uri);
  }, [navigate, id, ticket]);

  const handleKeyDown = useMemo(() => handleKeyboardSelect(handleClick), [handleClick]);

  const circleClassnames = classNames({
    [style.circle]: true,
    [style.circleCompleted]: isCompleted,
    [style.circleDisabled]: isDisabled,
    [style.completedBg]: isCompleted,
    [style.circleCurrent]: isActive,
  });

  const buttonClasses = classNames({
    [style.numberWrapper]: true,
    [style.buttonEnabled]: !isDisabled,
    [style.buttonDisabled]: isDisabled,
  });

  const stepNameClasses = classNames({
    [style.stepName]: true,
    [style.labelStepCompleted]: isCompleted,
    [style.stepDisabledFont]: isDisabled,
    [style.stepNameActive]: isActive,
  });

  const lineStyles = classNames({
    [style.line]: true,
    [style.lineBg]: !isCompleted,
    [style.completedBg]: isCompleted,
    [style.circleDisabled]: isDisabled,
  });

  return (
    <div className={stepContainerClasses}>
      <div
        id={`${id}-button`}
        aria-label={t(`stepsBar:${id}`)}
        aria-disabled={isDisabled}
        aria-current={isActive ? 'page' : 'false'}
        onKeyDown={isDisabled ? undefined : handleKeyDown}
        onClick={isDisabled ? undefined : handleClick}
        tabIndex={isDisabled ? -1 : 0}
        className={buttonClasses}
      >
        <div className={circleClassnames}>
          { isActive && <div className={style.selectedCircle}/> }
          {
            isCompleted
              ? <Check/> : (
                <span>{index}</span>
              )
          }
        </div>
        <span className={stepNameClasses}>
          {t(`stepsBar:${id}`)}
          <div className={style.clicker}></div>
        </span>
      </div>
      { index !== CONFIRM_STEP_INDEX ? <div className={lineStyles}/> : null}
    </div>
  );
};

export default Step;
