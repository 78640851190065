import { createContext, useCallback, useEffect, useState } from 'react';

import { MultiLingualStylesheet, Stylesheet } from '../interfaces/Stylesheet';
import Language from '../helpers/Language';
import { EnabledLanguage } from '../interfaces/Language';
import { FCWithChild } from '../interfaces/Shared';
import { getActiveStylesheet } from '../services/StylesheetService';

export interface StylesheetProviderStore {
  stylesheet: Stylesheet;
  changeStylesheetLanguage: (lng: EnabledLanguage) => void;
}

export const StylesheetContext = createContext({} as StylesheetProviderStore);

const updateFavicon = (faviconUrl: string): void => {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = `${faviconUrl}?=${Math.random()}`;

  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
};

interface StylesheetProviderProps {
  globalStylesheet: MultiLingualStylesheet;
  brandStylesheet: MultiLingualStylesheet | undefined;
}

const StylesheetProvider: FCWithChild<StylesheetProviderProps> = ({
  children,
  brandStylesheet,
  globalStylesheet
}) => {
  const [stylesheet, setUsedStylesheet] = useState<Stylesheet>(
    (brandStylesheet ? brandStylesheet : globalStylesheet)[Language.getLanguage()]
  );

  const changeStylesheetLanguage = (language: EnabledLanguage): void => {
    applyStylesheet(globalStylesheet, brandStylesheet, language);
  };

  const applyStylesheet = useCallback((
    globalStylesheet: MultiLingualStylesheet,
    brandStylesheet: MultiLingualStylesheet | undefined,
    language: EnabledLanguage
  ) => {
    const stylesheetWithTranslation = getActiveStylesheet(globalStylesheet, brandStylesheet, language);

    if (!stylesheetWithTranslation) {
      return;
    }

    // Must be done before changing state because it must remove the old reference
    if (stylesheet?.settings.cssReference) {
      document.body.classList.remove(stylesheet.settings.cssReference);
    }

    setUsedStylesheet(stylesheetWithTranslation);

    document.title = stylesheetWithTranslation.settings.pageTitle;
    updateFavicon(stylesheetWithTranslation.settings.faviconUrl);
    document.body.classList.add(stylesheetWithTranslation.settings.cssReference);
  }, [stylesheet]);

  useEffect(() => {
    applyStylesheet(globalStylesheet, brandStylesheet, Language.getLanguage());
  }, [globalStylesheet, brandStylesheet, applyStylesheet]);

  const store: StylesheetProviderStore = {
    stylesheet,
    changeStylesheetLanguage,
  };

  return (
    <StylesheetContext.Provider value={store}>
      {children}
    </StylesheetContext.Provider>
  );
};

export { StylesheetProvider };

export const StylesheetConsumer = StylesheetContext.Consumer;
