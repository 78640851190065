import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import style from './TermsAndConditions.module.scss';
import { useFormatting } from '../../services/hooks/useFormatting';
import classNames from 'classnames';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

interface TermsAndConditions {
  link: string;
  partner: string;
}

const TermsAndConditionsComponent: FC<TermsAndConditions> = ({ link, partner }) => {
  const { t } = useTranslation();
  const { formattedText: terms } = useFormatting(t('footer:terms', { partner, link }));

  const termsClasses = classNames('col-xs-12 col-md-7', style.termsText);

  return (
    <div className={style.terms}>
      {!!link && !!partner && <p className={termsClasses} dangerouslySetInnerHTML={{ __html: terms }} />}
      <div className={style.cookieStatementWrapper}>
        <p>
          <a rel="noopener noreferrer" target="_blank" href="https://www.guidion.com/privacy-cookie-statement">
            {t('footer:cookieStatement')}
          </a>
        </p>
        <LanguageSelector placement="footer-mobile" />
      </div>
    </div>
  );
};

export const TermsAndConditions = React.memo(TermsAndConditionsComponent);
