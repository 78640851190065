import classNames from 'classnames';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepsUpdateContext } from '../Steps/StepsContext';

import styles from './BackButton.module.scss';

interface BackButtonProps {
  text: string;
  ariaLabel?: string;
  useRouter?: boolean;
}

const BackButton: FC<BackButtonProps> = ({ text, ariaLabel, useRouter }) => {
  const { navigateToPrevStep } = useContext(StepsUpdateContext);
  const navigate = useNavigate();
  const btnClassNames = classNames(styles.btn, styles.backBtn);

  const navigateBack = (): void => {
    navigateToPrevStep();
  };

  return (
    <button
      tabIndex={0}
      className={btnClassNames}
      aria-label={ariaLabel}
      onClick={() => useRouter ? navigate(-1) : navigateBack()}
    >
      <i className="material-icons">chevron_left</i>
      <span>{text}</span>
    </button>
  );
};

export default BackButton;
