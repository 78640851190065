import { Stylesheet } from '../interfaces/Stylesheet';
import { Ticket } from '../interfaces/Ticket';
import DateTimeHelpers from './DateTime';

const getStartDateTomorrow = () => {
  let tomorrow = DateTimeHelpers.getDateXDaysInTheFuture(1);
  tomorrow = DateTimeHelpers.setDateToStartOfTheDay(tomorrow);

  return tomorrow;
};

const getConfirmStatuses = () => {
  return ['Completed', 'In Progress', 'On Route', 'Dispatched', 'Cannot Complete'];
};

const getStartDateCurrentTime = () => {
  return DateTimeHelpers.getDateXDaysInTheFuture(0);
};

const getMinimumStartDateForTicketFromNow = (firstPossiblePlanDate: Date, allowedSameDayScheduling: boolean): Date => {
  const earliestFallbackTime = allowedSameDayScheduling ? getStartDateCurrentTime() : getStartDateTomorrow();

  if (firstPossiblePlanDate < earliestFallbackTime) {
    return earliestFallbackTime;
  }

  return firstPossiblePlanDate;
};

const isTicketCancellable = (ticket: Ticket, stylesheet: Stylesheet) => {
  // allowCanceling is calculated based on the work days. But its not possible to switch it off.
  // For now that comes from the Stylesheet. This is being reevaluated in GDD-4405.
  return stylesheet.cancelSection.cancelingEnabled && ticket.workOrder.appointment.allowCancelling;
};

export { getMinimumStartDateForTicketFromNow, isTicketCancellable, getConfirmStatuses };
