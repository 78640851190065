import { useEffect, useState } from 'react';
import { isDeviceMobile } from '../Device';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(isDeviceMobile());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isDeviceMobile());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};
