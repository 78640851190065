import { FC, Fragment } from 'react';
import classNames from 'classnames';

import styles from './SmallActionContainer.module.scss';
import { Separator } from '../Separator/Separator';

type Action = {
  iconURL?: string;
  icon?: JSX.Element;
  header: JSX.Element;
  content: JSX.Element;
  alt: string;
};

interface SmallActionContainerProps {
  actions: Action[];
  wide?: boolean;
  mobileOnly?: boolean;
}

const SmallActionContainer: FC<SmallActionContainerProps> = ({ actions, wide, mobileOnly }) => {
  return (
    <div className={classNames({
      [styles.root]: true,
      [styles.row]: wide,
      [styles.column]: !wide,
      [styles.mobileOnly]: mobileOnly,
    })}>
      {
        actions.map((action, index) => (
          <Fragment key={index}>
            <div className={classNames({
              [styles.container]: true,
              [styles.smallContainer]: wide,
            })}>
              {action.iconURL && (
                <img
                  width={24}
                  height={24}
                  alt={action.alt}
                  src={action.iconURL} />
              )}
              {action.icon}
              <div className={styles.contentContainer}>
                <h3 className={classNames({
                  [styles.header]: true,
                })}>{action.header}</h3>
                <div className={styles.content}>{action.content}</div>

              </div>
            </div>
            {(index < actions.length - 1) && <Separator classes={classNames({
              [styles.separator]: true,
              [styles.separatorWide]: wide,
            })}/>}
          </Fragment>
        ))
      }
    </div>
  );
};

export default SmallActionContainer;
