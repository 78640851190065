import Language from '../helpers/Language';
import { Maintenance, MultiLingualStylesheet } from '../interfaces/Stylesheet';

const isInMaintenanceMode = (maintenance: Maintenance): boolean => {
  let inTimeframe: boolean = false;

  if (maintenance.startTime && maintenance.endTime) {
    const startTime = new Date(maintenance.startTime);
    const endTime = new Date(maintenance.endTime);
    const now = new Date();

    inTimeframe = (now > startTime && now < endTime);
  } else {
    // If not both start and end time is set, we only care about the status.
    inTimeframe = true;
  }

  return maintenance.status && inTimeframe;
};

const styleSheetIsInMaintenanceMode = (styleSheet: MultiLingualStylesheet): boolean => {
  const maintenance: Maintenance | null = styleSheet[Language.getLanguage()]?.maintenance;

  return maintenance && isInMaintenanceMode(maintenance);
};

export { isInMaintenanceMode, styleSheetIsInMaintenanceMode };
