import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Stylesheet } from '../../interfaces/Stylesheet';
import style from './Customer.module.scss';
import ContainerHeader from '../../components/ContainerHeader/ContainerHeader';

interface CustomerHeaderProps {
  stylesheet: Stylesheet;
  allowCancelling: boolean;
}

const CustomerHeader: FC<CustomerHeaderProps> = ({ stylesheet }) => {
  const { t } = useTranslation();

  return (
    <ContainerHeader
      title={t('customerSection:title', { partner: stylesheet.settings.partnerName })}
      text={stylesheet.customerSection.leadBlock.value}
      containerHeaderClassName={style.customerHeader}
    />
  );
};

export default CustomerHeader;
