export default {
  nl: {
    form: {
      title: 'Vul hier je gegevens in en wij komen zo spoedig mogelijk bij je terug.',
      personalDetails: 'Persoonsgegevens',
      initials: 'Initialen',
      middleName: 'Tussenvoegsel',
      lastName: 'Achternaam',
      phoneNumber: 'Telefoonnummer',
      mobileNumber: 'Mobiel nummer',
      email: 'E-mailadres',
      address: 'Adres',
      addressDetails: 'Adresgegevens',
      question: 'Stel hier je vraag...',
      correctDetails: 'Mijn gegevens zijn correct',
      requiredField: 'Veld is verplicht',
    }
  },
  en: {
    form: {
      title: 'Enter your details here and we will get back to you as soon as possible.',
      personalDetails: 'Personal details',
      initials: 'Initials',
      middleName: 'Middle name',
      lastName: 'Lastname',
      phoneNumber: 'Phone number',
      mobileNumber: 'Mobile number',
      email: 'E-mail',
      address: 'Address',
      addressDetails: 'Address details',
      question: 'Your question',
      correctDetails: 'My details are correct',
      requiredField: 'Required field',
    }
  }
};
