export default {
  nl: {
    generalAlts: {
      loading: 'Laden',
    },
    headerAlts: {
      nlFlag: 'Vlag van Nederland',
      ukFlag: 'Vlag van Groot Brittannië',
    },
    errorAlts: {
      onCallEmployee: 'Medewerker Guidion'
    },
    expertCardAlts: {
      expertPhotograph: 'Foto van monteur'
    },
    contactPageAlts: {
      technicians: 'Foto van twee monteurs',
      office: 'Foto van een medewerker op kantoor',
    }
  },
  en: {
    generalAlts: {
      loading: 'Loading',
    },
    headerAlts: {
      nlFlag: 'Flag of the Netherlands',
      ukFlag: 'Flag of Great Britain',
    },
    errorAlts: {
      onCallEmployee: 'Guidion Employee'
    },
    expertCardAlts: {
      expertPhotograph: "Expert's photograph"
    },
    contactPageAlts: {
      technicians: 'Photo of two technicians',
      office: 'Photo of an employee in the office',
    }
  }
};
