import { FC, useContext, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './Error.module.scss';
import { getErrorTitle, SupportedErrorTypes } from '../../services/ErrorService';
import { TicketContext } from '../../contexts/TicketContext';
import MaintenanceError from './MaintenanceError';
import SquareAnimatedImage from '../../components/SquareAnimatedImage/SquareAnimatedImage';

import usePageTitle from '../../services/hooks/usePageTitle';
import { ErrorOutletContext } from '../../services/Outlet';

const Error: FC = () => {
  const { activeStylesheet, errorType } = useOutletContext<ErrorOutletContext>();
  const { setIsInErrorMode } = useContext(TicketContext);

  const { t } = useTranslation();

  usePageTitle(t('pageTitle:error'));

  useEffect(() => {
    setIsInErrorMode(true);
  }, [setIsInErrorMode]);

  const showMaintenanceError = errorType === SupportedErrorTypes.maintenance && activeStylesheet;
  const showExpirationParagraph = errorType === SupportedErrorTypes.noSignatureFound;

  return (
    <div className={styles.error}>
      {showMaintenanceError ? (
        <MaintenanceError maintenance={activeStylesheet.maintenance} />
      ) : (
        <div className={styles.container}>
          <SquareAnimatedImage image={{ type: 'svg', name: 'error' }} ariaLabel="Error" />
          <h2>{getErrorTitle(t, errorType)}</h2>

          {showExpirationParagraph && <p className={styles.expiredParagraph}>{t('errorMessages:signatureNotFoundParagraph')}</p>}
        </div>
      )}
    </div>
  );
};

export default Error;
