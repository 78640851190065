import React, { ReactNode, useEffect } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import styles from './StickyWrapper.module.scss';
import StepsService from '../../services/StepsService';

interface StepsProps {
  children?: ReactNode;
  setIsSticky: (isSticky: boolean) => void;
  isSticky: boolean;
}

let previousScrollValue = 0;

const StickyWrapperComponent = ({ children, setIsSticky, isSticky }: StepsProps): JSX.Element => {
  const { pathname } = useLocation();

  useEffect(() => {
    setIsSticky(false);
  }, [pathname, setIsSticky]);

  useEffect(() => {
    const onScroll = (event: Event): void => {
      const currentScrollValue = (event.target as Document)?.scrollingElement?.scrollTop || 0;
      const scrollTopMax = (event.target as Document)?.scrollingElement!.scrollHeight
        - (event.target as Document)?.scrollingElement!.clientHeight;

      if (currentScrollValue < 0 || currentScrollValue > scrollTopMax) {
        return;
      }

      if (currentScrollValue > previousScrollValue) {
        setIsSticky(true);
      } else if (currentScrollValue < previousScrollValue) {
        setIsSticky(false);
      }

      previousScrollValue = currentScrollValue;
    };

    window.addEventListener('scroll', onScroll);

    return (): void => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [setIsSticky]);

  const stepsClasses = classnames(
    styles.stickyWrapper,
    StepsService.showStepperBackground(pathname) && 'header-opacity-background',
    isSticky ? styles.hideHeader : styles.showHeader,
  );

  return (
    <div className={stepsClasses} id="sticky">
      {children}
    </div>
  );
};

export const StickyWrapper = React.memo(StickyWrapperComponent);
