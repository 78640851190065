import { FC } from 'react';

interface MapPinProps {
  className?: string;
}

export const MapPin: FC<MapPinProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M16.2889 3.49097C9.91047 3.49097 4.73975 8.66169 4.73975 15.0401C4.73975 18.9237 7.06211 22.2157 9.73802 24.5348L14.1542 28.3621C15.3793 29.4239 17.1985 29.4239 18.4236 28.3621L22.8397 24.5348C25.5157 22.2157 27.838 18.9237 27.838 15.0401C27.838 8.66169 22.6673 3.49097 16.2889 3.49097Z" fill="#BABDCC"/>
      <path d="M18.8556 15.0401C18.8556 16.4575 17.7066 17.6066 16.2891 17.6066C14.8717 17.6066 13.7227 16.4575 13.7227 15.0401C13.7227 13.6227 14.8717 12.4736 16.2891 12.4736C17.7066 12.4736 18.8556 13.6227 18.8556 15.0401Z" fill="#070809"/>
    </svg>
  );
};
