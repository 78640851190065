/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

export const useDocumentBodyStyle = (style: Partial<CSSStyleDeclaration>) => {
  useEffect(() => {
    const originalStyles: Partial<CSSStyleDeclaration> = {};
    Object.keys(style).forEach((key: any) => {
      originalStyles[key] = document.body.style[key];
      document.body.style[key] = style[key as keyof CSSStyleDeclaration] as string;
    });

    return () => {
      Object.keys(style).forEach((key: any) => {
        document.body.style[key] = originalStyles[key] || '';
      });

      const hasAnyStyles = document.body.style.length > 0;

      if (!hasAnyStyles) {
        document.body.removeAttribute('style');
      }
    };
  }, [style]);
};
