import classnames from 'classnames';

import style from './Card.module.scss';
import { FCWithChildren } from '../../interfaces/Shared';

interface CardProps {
  className?: string;
}

const Card: FCWithChildren<CardProps> = ({ className, children }) => {
  const classes = classnames(style.container, className);

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export default Card;
