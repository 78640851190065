import { Outlet, useLoaderData } from 'react-router-dom';
import classNames from 'classnames';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import FadeInOutWrapper from '../FadeInOutWrapper/FadeInOutWrapper';
import { ErrorOutletContext } from '../../services/Outlet';
import { StylesheetProvider } from '../../contexts/StylesheetContext';
import { TicketProvider } from '../../contexts/TicketContext';
import { useMaintenanceGuard } from '../../helpers/hooks/useMaintenanceGuard';
import { hideSpinner } from '../../services/Spinner';

export const ErrorOutlet = () => {
  const loaderData = useLoaderData() as ErrorOutletContext;
  const { globalStylesheet, isInMaintenanceMode } = loaderData;

  useMaintenanceGuard(isInMaintenanceMode);
  hideSpinner();

  const pageClasses = classNames(
    'page',
    'pageWithoutStepper',
  );

  return (
    <>
      <TicketProvider>
        <StylesheetProvider globalStylesheet={globalStylesheet} brandStylesheet={undefined}>
          <>
            <Header />
            <main className={pageClasses}>
              <FadeInOutWrapper isEnabled>
                <div className="container">
                  <Outlet context={loaderData} />
                </div>
              </FadeInOutWrapper>
            </main>
            <Footer />
          </>
        </StylesheetProvider>
      </TicketProvider>
    </>
  );
};
