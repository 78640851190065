/* eslint-disable @stylistic/max-len */

import classNames from 'classnames';
import { FC } from 'react';

import style from './DateBanner.module.scss';
import DateTime from '../../../helpers/DateTime';

const CalendarIcon = () => <svg width="118" viewBox="0 0 118 117" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="98.7286" height="100.729" transform="translate(10.457 10.0781)" fill="black" fillOpacity="0.2"/>
  <path d="M117.191 0.738953H18.4628V101.468H117.191V0.738953Z" fill="white"/>
  <path d="M117.191 0.738953H18.4628V16.0813H117.191V0.738953Z" fill="#FF6555"/>
  <path d="M28.802 10.745C30.3677 10.745 31.6369 9.47574 31.6369 7.91008C31.6369 6.34442 30.3677 5.0752 28.802 5.0752C27.2364 5.0752 25.9672 6.34442 25.9672 7.91008C25.9672 9.47574 27.2364 10.745 28.802 10.745Z" fill="white"/>
  <path d="M106.368 10.745C107.934 10.745 109.203 9.47574 109.203 7.91008C109.203 6.34442 107.934 5.0752 106.368 5.0752C104.803 5.0752 103.534 6.34442 103.534 7.91008C103.534 9.47574 104.803 10.745 106.368 10.745Z" fill="white"/>
  <rect x="0.32135" y="84.6924" width="31.8545" height="31.8545" rx="15.9272" fill="#64C95B"/>
  <rect x="1.76928" y="86.1403" width="28.9586" height="28.9586" rx="14.4793" stroke="#272D33" strokeOpacity="0.2" strokeWidth="2.89586"/>
  <path d="M13.8553 102.751L11.6415 100.539C11.2263 100.124 10.555 100.124 10.1398 100.539C9.72447 100.954 9.72447 101.625 10.1398 102.04L13.1078 105.006C13.523 105.421 14.1944 105.421 14.6095 105.006L22.1219 97.4993C22.5372 97.0843 22.5372 96.4129 22.1219 95.9979C21.7067 95.583 21.0354 95.583 20.6202 95.9979M13.8553 102.751L20.6202 95.9979M13.8553 102.751L20.6202 95.9979M13.8553 102.751L20.6202 95.9979M20.6202 95.9979L20.6203 95.9978L20.8705 96.2484L20.6202 95.9979Z" fill="white" stroke="white" strokeWidth="0.708152" strokeLinejoin="round"/>
</svg>;

const CalendarIconCheck = () => (
  <svg
    width="118"
    viewBox="0 0 118 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="98.7286"
      height="100.729"
      transform="translate(10.457 10.0781)"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M117.191 0.738953H18.4628V101.468H117.191V0.738953Z"
      fill="white"
    />
    <path
      d="M117.191 0.738953H18.4628V16.0813H117.191V0.738953Z"
      fill="#FF6555"
    />
    <path
      d="M28.802 10.745C30.3677 10.745 31.6369 9.47574 31.6369 7.91008C31.6369 6.34442 30.3677 5.0752 28.802 5.0752C27.2364 5.0752 25.9672 6.34442 25.9672 7.91008C25.9672 9.47574 27.2364 10.745 28.802 10.745Z"
      fill="white"
    />
    <path
      d="M106.368 10.745C107.934 10.745 109.203 9.47574 109.203 7.91008C109.203 6.34442 107.934 5.0752 106.368 5.0752C104.803 5.0752 103.534 6.34442 103.534 7.91008C103.534 9.47574 104.803 10.745 106.368 10.745Z"
      fill="white"
    />
  </svg>
);

interface DayOfMonthProps {
  date: string | Date | undefined;
  className?: string;
  icon: 'check' | 'calendar';
}

export const DateBanner: FC<DayOfMonthProps> = ({
  date,
  className,
  icon,
}) => {
  const wrapperClasses = classNames(style.dateBanner, className);
  const dayNumberClasses = classNames(style.dayNumber);

  const dayNumber = date ? DateTime.getDate(new Date(date)) : undefined;

  return (
    <div className={wrapperClasses}>
      {icon === 'check' && (
        <>
          <div className={style.calendarIcon}>
            <CalendarIconCheck />
          </div>
          <div className={dayNumberClasses}>
            <i
              aria-hidden="true"
              className="material-icons"
              style={{ fontSize: 50, fontWeight: 600 }}
            >
              check
            </i>
          </div>
        </>
      )}
      {icon === 'calendar' && (
        <>
          <div className={style.calendarIcon}>
            <CalendarIcon />
          </div>
          <div className={dayNumberClasses}>{dayNumber}</div>
        </>
      )}
    </div>
  );
};
