import { FC, useCallback, useContext } from 'react';
import { DateBanner } from '../DateBanner/DateBanner';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '../../../services/hooks/useFormatting';
import { DateRange } from '../../../services/ContentfulService';
import Calendar from '../Calendar/Calendar';
import { TicketContext } from '../../../contexts/TicketContext';
import { Ticket } from '../../../interfaces/Ticket';
import { StylesheetContext } from '../../../contexts/StylesheetContext';

import style from './ConfirmCard.module.scss';
import { HeaderSvg } from '../HeaderSvg/HeaderSvg';
import classNames from 'classnames';


export interface ConfirmCardProps {
  shouldDisplaySelectedNoTopSlots: boolean;
}

export const ConfirmCard: FC<ConfirmCardProps> = (props) => {
  const { shouldDisplaySelectedNoTopSlots } = props;
  const { t } = useTranslation();
  const { stylesheet } = useContext(StylesheetContext);
  const { ticket } = useContext(TicketContext);

  const appointmentRange: DateRange | undefined = ticket ? {
    start: ticket.workOrder.appointment.arrivalWindowStart!,
    finish: ticket.workOrder.appointment.arrivalWindowEnd!
  } : undefined;

  const { formattedText: appointmentTitle } = useFormatting(t('confirmSection:confirmedAppointment'), {
    replaceDate: appointmentRange?.start,
    range: appointmentRange ? {
      start: appointmentRange.start,
      finish: appointmentRange.finish
    } : undefined,
  });

  const getTitle = useCallback((ticket: Ticket) => {
    if (shouldDisplaySelectedNoTopSlots) {
      return t('confirmSection:selectedNoTopSlotsContentTitle');
    }

    switch (ticket.workOrder.appointment.status) {
      case 'On Route':
        return t('confirmSection:expertOnWay');
      case 'Completed':
      case 'Cannot Complete':
      case 'In Progress':
        return t('confirmSection:expertPassedBy');
      default:
        return appointmentTitle;
    }
  }, [appointmentTitle, shouldDisplaySelectedNoTopSlots, t]);

  const getDescription = useCallback((ticket: Ticket) => {
    if (shouldDisplaySelectedNoTopSlots){
      return t('confirmSection:selectedNoTopSlotsDescription');
    }

    switch (ticket.workOrder.appointment.status){
      case 'New':
      case 'Planned':
      case 'Dispatched':
      case 'On Route':
        return t('confirmSection:confirmedAppointmentDetails');
    }
  }, [shouldDisplaySelectedNoTopSlots, t]);

  if (!ticket || !stylesheet){
    return null;
  }

  const { appointment } = ticket.workOrder;

  const description = getDescription(ticket);

  const isCalendarVisible = !!appointmentRange?.start || shouldDisplaySelectedNoTopSlots;

  return (
    <section className={style.confirmCard}>
      <HeaderSvg />
      <div className={style.content}>
        {isCalendarVisible && (
          <DateBanner
            className={style.dateBanner}
            date={appointmentRange?.start}
            icon={shouldDisplaySelectedNoTopSlots ? 'check' : 'calendar'}
          />
        )}
        <h1 className={classNames(style.title, !isCalendarVisible && style.titleCalendarHidden)}>{getTitle(ticket)}</h1>

        {description && <p>{description}</p>}

        {appointment.status === 'Dispatched' && (
          <Calendar appointment={appointment} partnerName={stylesheet.settings.partnerName} text={t('confirmSection:agenda')} />
        )}
      </div>
    </section>
  );
};
