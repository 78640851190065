import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import StepsService from '../../services/StepsService';
import { StepModel } from './interfaces/Step';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { TicketContext } from '../../contexts/TicketContext';
import { SupportedErrorTypes } from '../../services/ErrorService';
import { setNextStepGA } from '../../services/Analytics';
import { usePrevious } from '../../services/hooks/usePrevious';
import { FCWithChildren } from '../../interfaces/Shared';

export interface StepsProviderStore {
  steps: StepModel[];
}

export interface StepsUpdateProviderStore {
  completeCurrentStep: () => void;
  navigateToNextStep: (navigationOptions?: NavigateOptions) => void;
  navigateToPrevStep: () => void;
  navigateToActiveStep: () => void;
  navigateToError: (id: SupportedErrorTypes) => void;
  disableStepsOnRight: () => void;
}

export const StepsContext = createContext({} as StepsProviderStore);
export const StepsUpdateContext = createContext({} as StepsUpdateProviderStore);

const StepsProvider: FCWithChildren = ({ children }) => {
  const { ticket } = useContext(TicketContext);
  const { isInErrorMode } = useContext(TicketContext);
  const defaultSteps = useMemo(() => StepsService.getDefaultSteps(ticket, isInErrorMode), [ticket, isInErrorMode]);
  const [steps, setSteps] = useState<StepModel[]>(defaultSteps);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const previousInErrorMode = usePrevious<boolean>(isInErrorMode);

  useEffect(() => {
    if (typeof previousInErrorMode !== 'boolean' || previousInErrorMode === isInErrorMode) {
      return;
    }

    const newSteps = isInErrorMode
      ? StepsService.getStepsAs(steps, { isDisabled: true })
      : defaultSteps;

    setSteps(newSteps);
  }, [defaultSteps, isInErrorMode, previousInErrorMode, steps, ticket]);

  useEffect(() => {
    if (steps.length === 0 && defaultSteps.length > 0) {
      setSteps(defaultSteps);
    }
  }, [defaultSteps, steps]);

  const getCurrentStepIndex = (): number => steps.findIndex(item => item.uri === pathname);

  const completeCurrentStep = (): void => {
    const newSteps = [...steps];
    const index = getCurrentStepIndex();
    const nextStepIndex = index + 1;

    if (nextStepIndex < steps.length) {
      newSteps[index] = { ...newSteps[index], isCompleted: true, isDisabled: false };
      newSteps[nextStepIndex] = { ...newSteps[nextStepIndex], isDisabled: false };
      setNextStepGA(newSteps[nextStepIndex].id);
      setSteps(newSteps);
    }
  };

  const navigateToNextStep = (navigationOptions?: NavigateOptions): void => {
    const nextStepIndex = getCurrentStepIndex() + 1;

    if (nextStepIndex < steps.length) {
      navigate(steps[nextStepIndex].uri, navigationOptions);
    }
  };

  const navigateToPrevStep = (): void => {
    const prevStepIndex = getCurrentStepIndex() - 1;

    if (prevStepIndex >= 0) {
      navigate(steps[prevStepIndex].uri);
    }
  };

  const navigateToActiveStep = (): void => {
    const activeStep = StepsService.getActiveStep(steps);

    navigate(activeStep.uri);
  };

  const navigateToError = (id: SupportedErrorTypes): void => {
    navigate(`/error/${id}`);
  };

  const disableStepsOnRight = (): void => {
    const currentIndex = getCurrentStepIndex();

    const nextSteps = [
      ...steps.slice(0, currentIndex),
      { ...steps[currentIndex], isDisabled: false, isCompleted: false },
      ...(StepsService.getStepsAs(steps.slice(currentIndex + 1), { isDisabled: true, isCompleted: false }))
    ];

    setSteps(nextSteps);
  };

  const store = { steps };

  return (
    <StepsContext.Provider value={store}>
      <StepsUpdateContext.Provider value={{
        completeCurrentStep,
        disableStepsOnRight,
        navigateToActiveStep,
        navigateToNextStep,
        navigateToPrevStep,
        navigateToError,
      }}>
        {children}
      </StepsUpdateContext.Provider>
    </StepsContext.Provider>
  );
};

export { StepsProvider };

export const StepsConsumer = StepsContext.Consumer;
