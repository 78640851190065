import classNames from 'classnames';

import style from './Card.module.scss';
import MaterialIcon, { IconNames } from '../MaterialIcon/MaterialIcon';
import { FCWithChild } from '../../interfaces/Shared';

interface CardItemProp {
  className?: string;
  icon?: IconNames;
  iconColor?: string;
}

const CardItem: FCWithChild<CardItemProp> = ({ className, icon, children, iconColor }) => {
  const classes = classNames(style.cardItem, className);
  const iconClassNames = classNames('material-icons', style.itemIcon);

  return (
    <div className={classes}>
      {!!icon && <MaterialIcon className={iconClassNames} name={icon} style={{ color: iconColor }} />}
      {children}
    </div>
  );
};

export default CardItem;
