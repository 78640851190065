import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Stylesheet } from '../../interfaces/Stylesheet';
import { Customer } from '../../interfaces/Customer';

import style from './AddressData.module.scss';
import FormElement from '../Form/FormElement';
import { useFormatting } from '../../services/hooks/useFormatting';
import StepsService from '../../services/StepsService';
import { useNavigate } from 'react-router-dom';
import Alert from '../Alert/Alert';

interface AddressDataProps {
  stylesheet: Stylesheet;
  customer: Customer;
  allowCancelling: boolean;
  hashId: string;
}

const AddressData: FC<AddressDataProps> = ({ stylesheet, customer: { address }, allowCancelling, hashId }) => {
  const containerClasses = classnames('col col-xs-12 col-xl-6', style.sidebar);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { street, postalCode, city } = address;

  const formattedAddress = `${street}, ${postalCode}, ${city}`;
  const { formattedText: alertText } = useFormatting(stylesheet!.customerSection.alertMessageText);

  return (
    <div className={containerClasses}>
      <h2>{t('form:addressDetails')}</h2>
      <FormElement
        type="text"
        name="disabled"
        value={formattedAddress}
        disabled
        label={t('form:address')}
      />
      <p dangerouslySetInnerHTML={{ __html: alertText }} />

      {
        allowCancelling ? (
          <div className={style.desktopOnly}>
            <Alert
              text={t('alert:cancelTicket')}
              className={style.cancellation}
              level="info"
              textButton={{ text: t('alert:clickToCancel'), onClick: () => navigate(StepsService.getStepUri(hashId, 'cancel')) }}
            />
          </div>
        ) : null
      }
    </div>
  );
};

export default AddressData;
