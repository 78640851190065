import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { errorUrls, initTypes } from '../services/RedirectService';
import StepsService from '../services/StepsService';

export const WildcardRedirect: FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={errorUrls.ticketNotFound} />} />
    </Routes>
  );
};


interface BackwardsCompatibilityRedirectProps {
  initType: string;
}

export const BackwardsCompatibilityRedirect: FC<BackwardsCompatibilityRedirectProps> = ({ initType }) => {
  const getHashIdPathParameter = () => {
    const { pathname } = window.location;

    // match 't' or 'c' or 'sign' regex
    const allowedRedirectPrefixes = `[${initTypes.ticket}|${initTypes.cancel}|${initTypes.signature}]`;

    const hashIdCaptureRegex = new RegExp(`${allowedRedirectPrefixes}\/([A-Za-z0-9]+)\/?.*`);
    const hashIdMatch = pathname.match(hashIdCaptureRegex);

    return hashIdMatch?.[1] ? hashIdMatch[1] : undefined;
  };

  const getRoute = (initType: string): string => {
    switch (initType) {
      case initTypes.cancel:
        return 'cancel';
      case initTypes.signature:
        return 'sign';
      case initTypes.ticket:
      default:
        return '';
    }
  };

  return (
    <Routes>
      <Route
        path="*"
        element={<Navigate to={StepsService.getStepUri(getHashIdPathParameter(), getRoute(initType))} />}
      />
    </Routes>
  );
};
