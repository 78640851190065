import { BackendRepository } from '../api/interfaces/BackendRepository';
import BackendRepositoryRemote from '../api/BackendRepositoryRemote';
import { BusinessReviews } from '../interfaces/BusinessReviews';

export default class BusinessReviewsService {
  private repository: BackendRepository;

  constructor() {
    this.repository = new BackendRepositoryRemote();
  }

  public async getBusinessReviews(): Promise<BusinessReviews> {
    return this.repository.getBusinessReviews();
  }
}
