import { FC, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import style from './Footer.module.scss';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { TermsAndConditions } from '../TermsAndConditions/TermsAndConditions';
import { PreFooter } from '../PreFooter/PreFooter';
import StepsService from '../../services/StepsService';
import { TicketContext } from '../../contexts/TicketContext';

const Footer: FC = (): JSX.Element | null => {
  const { stylesheet } = useContext(StylesheetContext);
  const { pathname } = useLocation();
  const { ticket } = useContext(TicketContext);
  const shouldHideFooter = pathname.includes('/contact');

  if (shouldHideFooter) {
    return null;
  }

  const { settings } = stylesheet;
  const pagesWithWhiteBackground = [
    StepsService.getStepUri(ticket?.hashId, 'customer'),
    StepsService.getStepUri(ticket?.hashId, 'contact')
  ];
  const wraperClass = classNames(style.wrapper, !pagesWithWhiteBackground.includes(pathname) && style.withBorderRadius);

  return (
    <footer>
      <div className={wraperClass}>
        <div className="container">
          <PreFooter />
          <TermsAndConditions partner={settings.partnerName} link={settings.termsUrl} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
