import { BackendRepository } from '../api/interfaces/BackendRepository';
import BackendRepositoryRemote from '../api/BackendRepositoryRemote';
import { NoTopSlot, NoTopSlotRange } from '../interfaces/NoTopSlot';
import { sendGAEvent } from './Analytics';

export interface GetNoTopSlotsResult {
  noTopSlots: NoTopSlot[];
}

export default class NoTopSlotService {
  private repository: BackendRepository;

  constructor(repository?: BackendRepository) {
    this.repository = repository || new BackendRepositoryRemote();
  }

  public async getNoTopSlots(ticketHashId: string, workOrderId: string): Promise<GetNoTopSlotsResult> {
    let noTopSlots: NoTopSlot[] = [];

    try {
      noTopSlots = await this.repository.getNoTopSlots(ticketHashId, workOrderId);
    } catch {
      sendGAEvent('scheduling', 'get-no-slots-failed');
    }

    return {
      noTopSlots
    };
  }
}

export const getIsAllPeriodsSelected = (periods: NoTopSlotRange[]) => {
  return !periods.some((period) => period.selected === false);
};
