import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { StylesheetContext } from '../../contexts/StylesheetContext';
import { TicketContext } from '../../contexts/TicketContext';
import { sendConfirmShown24Hours } from '../../services/Analytics';
import { useFormatting } from '../../services/hooks/useFormatting';
import usePageTitle from '../../services/hooks/usePageTitle';
import DateTime from '../../helpers/DateTime';
import ExpertCard from './ExpertCard/ExpertCard';
import ConfirmReschedule from './ConfirmReschedule';
import NoSlotsSummary from '../../components/NoSlots/NoSlotsSummary';
import FeedbackWidget from '../../components/Typeform/FeedbackWidget';

import styles from './Confirm.module.scss';
import { isTicketCancellable } from '../../helpers/TicketHelpers';
import { NoTopSlot } from '../../interfaces/NoTopSlot';
import { ConfirmCard } from './ConfirmCard/ConfirmCard';
import { AboutAppointment, AboutAppointmentContent } from './AboutAppointment/AboutAppointment';
import { Separator } from '../../components/Separator/Separator';
import { Ticket } from '../../interfaces/Ticket';


const Confirm: FC = () => {
  const { stylesheet } = useContext(StylesheetContext);
  const { ticket, showFeedback, fetchTicket } = useContext(TicketContext);
  const { state } = useLocation();
  const { t } = useTranslation();
  const selectedNoTopSlots: NoTopSlot[] | undefined = useMemo(() => state?.selectedNoTopSlots, [state]);

  usePageTitle(t('pageTitle:confirm'));

  const within24hours = ticket?.workOrder.appointment?.arrivalWindowStart ?
    DateTime.withinDayBefore(ticket.workOrder.appointment.arrivalWindowStart) : false;

  useEffect(() => {
    fetchTicket(ticket?.hashId || '');
    if (within24hours) {
      sendConfirmShown24Hours();
    }
  }, [fetchTicket, within24hours, ticket?.hashId]);

  const appointmentDurationRange = ticket ? {
    start: ticket.workOrder.appointment.scheduledStart!,
    finish: ticket.workOrder.appointment.scheduledEnd!
  } : undefined;

  const { formattedText: averageTime } = useFormatting(t('confirmSection:averageVisit'), {
    replaceDate: ticket?.workOrder.appointment.arrivalWindowStart || null,
    range: appointmentDurationRange,
    roundTo: 5
  });

  const { formattedText: warningText } = useFormatting(stylesheet?.confirmationSection.warningText);


  const getExpertTitle = useCallback((ticket: Ticket) => {
    switch (ticket.workOrder.appointment.status) {
      case 'Completed':
      case 'Cannot Complete':
      case 'In Progress':
        return t('confirmSection:whoWasThere');
      default:
        return t('confirmSection:whosComing');
    }
  }, [t]);

  const getAboutAppointmentData = useCallback((contentTitle: string, moreThan24HoursBefore: boolean) => {
    const base: AboutAppointmentContent[] = [];

    if (moreThan24HoursBefore) {
      base.push({ icon: 'info', title: contentTitle, description: warningText, iconColor: '#FFAC4B' });
    }

    base.push({ icon: 'schedule', title: contentTitle, description: averageTime });

    return base;
  }, [averageTime, warningText]);

  const shouldDisplaySelectedNoTopSlots = !!selectedNoTopSlots?.length;

  if (!stylesheet || !ticket || !stylesheet.confirmationSection) {
    return null;
  }

  const feedbackQuestionsId = stylesheet.settings.feedbackQuestions?.id;
  const serviceAppointmentId = ticket.workOrder.appointment?.id || '';
  const appointment = ticket.workOrder.appointment;
  const moreThan24HoursBefore = appointment.arrivalWindowStart ?
    DateTime.moreThanDayBefore(appointment.arrivalWindowStart) : false;

  const lessThan24HoursAfter = appointment.scheduledEnd ? DateTime.lessThanDayAfter(appointment.scheduledEnd) : false;

  const reschedulingEnabled = ticket.workOrder.appointment.allowRescheduling;
  const cancelingEnabled = isTicketCancellable(ticket, stylesheet);

  return (
    <div className={styles.ticketConfirm}>
      <ConfirmCard shouldDisplaySelectedNoTopSlots={shouldDisplaySelectedNoTopSlots} />

      <div className={styles.confirmContent}>
        <Separator />

        {shouldDisplaySelectedNoTopSlots && (
          <div className={styles.timeSlotContainer}>
            <NoSlotsSummary selectedNoTopSlots={selectedNoTopSlots} />
          </div>
        )}

        {lessThan24HoursAfter && <AboutAppointment
          title={t('confirmSection:aboutYourVisit')}
          content={getAboutAppointmentData(stylesheet.confirmationSection.contentTitle, moreThan24HoursBefore)}
        />}

        {(reschedulingEnabled || cancelingEnabled) && (
          <>
            <Separator />
            <ConfirmReschedule
              cancelingEnabled={cancelingEnabled}
              reschedulingEnabled={reschedulingEnabled}
              availabilityProvided={shouldDisplaySelectedNoTopSlots}
              hashId={ticket.hashId}
            />
          </>
        )}

        {!!within24hours && !!appointment.expertId && !!lessThan24HoursAfter && (
          <>
            <Separator />
            <div className={styles.visitcardContainer}>
              <h2 className={styles.expertTitle}>{getExpertTitle(ticket)}</h2>
              <ExpertCard ticketId={ticket.hashId} />
            </div>
          </>
        )}
        {showFeedback && feedbackQuestionsId && serviceAppointmentId && (
          <>
            <Separator />
            <div className={styles.visitcardContainer}>
              <h2 className={styles.feedbackTitle}>{t('confirmSection:feedbackTitle')}</h2>
              <FeedbackWidget feedbackQuestionsId={feedbackQuestionsId} serviceAppointmentId={serviceAppointmentId} />
            </div>
          </>

        )}
      </div>
    </div>
  );
};

export default Confirm;
