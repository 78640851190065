import { FC, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { StylesheetContext } from '../../contexts/StylesheetContext';
import { PreHeader } from '../PreHeader/PreHeader';
import { StickyWrapper } from './StickyWrapper';
import Steps from '../Steps/Steps';
import StepsService from '../../services/StepsService';

const Header: FC = () => {
  const { stylesheet } = useContext(StylesheetContext);
  const { pathname } = useLocation();
  const [isSticky, setIsSticky] = useState(false);

  if (!stylesheet) {
    return null;
  }

  return (
    <StickyWrapper setIsSticky={setIsSticky} isSticky={isSticky}>
      <PreHeader settings={stylesheet.settings} />
      {StepsService.showSteps(pathname) && <Steps /> }
    </StickyWrapper>
  );
};

export default Header;
