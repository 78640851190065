export default {
  nl: {
    alert: {
      saveSuccess: 'De gegevens zijn bijgewerkt.',
      savingDetails: 'Gegevens bijwerken ...',
      saveError: 'De gegevens konden niet worden opgeslagen.',
      cancelTicket: 'Toch geen hulp van onze monteur meer nodig?',
      clickToCancel: 'Klik hier om te annuleren.',
      bookingError: 'Dit tijdslot is helaas niet meer beschikbaar. Vernieuw de pagina om opnieuw tijdsloten op te halen',
      refreshPage: 'Vernieuw de pagina',
      somethingWentWrong: 'Er is iets misgegaan. Probeer het opnieuw',
    }
  },
  en: {
    alert: {
      saveSuccess: 'The details have been updated.',
      savingDetails: 'Updating details ...',
      saveError: 'The details could not be saved.',
      cancelTicket: 'Do you no longer need a visit from our expert?',
      clickToCancel: 'Click here to cancel.',
      bookingError: 'This time slot is no longer available. Refresh the page to retrieve time slots again',
      refreshPage: 'Refresh the page',
      somethingWentWrong: 'Something went wrong. Please try again.',
    }
  }
};
