import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import style from './PreFooter.module.scss';
import BusinessReviews from '../BusinessReviews/BusinessReviews';
import { Separator } from '../Separator/Separator';
import { ChatContainer } from '../ChatContainer/ChatContainer';
import { FAQPhoneContainer } from '../FAQPhoneContainer/FAQPhoneContainer';

const PreFooterComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <div className={style.titleSectionWrapper}>
        <div>
          <h2>{t('footer:title')}<br /> <span className={style.titleHighlight}>Guidion</span>.</h2>
        </div>

        <Separator classes={classNames(style.permanentSeparator, style.firstSeparator)} />

        <div className={style.businessReviews}>
          <BusinessReviews />
        </div>
      </div>

      <Separator classes={style.mobileOnlySeparator}/>

      <div>
        <h2>{t('footer:questions')}</h2>
        <div className={style.contactOptions}>
          <>
            <ChatContainer/>
            <FAQPhoneContainer/>
          </>
        </div>

        <Separator classes={style.permanentSeparator} />
      </div>
    </div>
  );
};

export const PreFooter = React.memo(PreFooterComponent);
