import './themes/main.scss';

import { FC } from 'react';
import { Outlet, RouterProvider } from 'react-router-dom';

import GAListener from './components/Analytics/Analytics';

import { BusinessReviewsProvider } from './contexts/BusinessReviewsContext';
import { sentryCreateBrowserRouter } from './components/Analytics/SentryIntegration';
import { routes } from './routes';

export const App: FC = () => {
  return (
    <GAListener>
      <BusinessReviewsProvider>
        <Outlet />
      </BusinessReviewsProvider>
    </GAListener>
  );
};

const router = sentryCreateBrowserRouter([
  { path: '/*', Component: App, children: routes },
]);

export const AppRoot: FC = () => {
  return <RouterProvider router={router} />;
};
