import { Ticket } from '../../interfaces/Ticket';
import { Stylesheet } from '../../interfaces/Stylesheet';
import Language from '../../helpers/Language';

const getQuestionStylesheetUrl = (stylesheet: Stylesheet) => {
  const cssReference = stylesheet.settings.cssReference;
  // The reference always has the name partner-stylesheet. Our generation is the same without the -stylesheet
  const stylesheetName = cssReference.replace('-stylesheet', '');

  return `${import.meta.env.VITE_CDN_BASEURL}/questionary-styles/${stylesheetName}.css`;
};

const getQuestionsUrl = (stylesheet?: Stylesheet, ticket?: Ticket): string => {
  if (!stylesheet?.questionSection || !ticket) {
    return '';
  }

  const instanceUrl = `${import.meta.env.VITE_QUESTIONNAIRE_URL}`;
  const lang = `&lang=${Language.getLanguage()}`;
  const workOrderId = `&WorkOrderID=${ticket.workOrder.id}`;
  const workType = `&WorkType=${ticket.workOrder.workTypeId}`;
  const engineFinished = `&Engine_finished=${ticket.workOrder.isQuestionnaireCompleted}`;
  const woStatus = `&WorkOrderStatus=${ticket.workOrder.status}`;
  const desc = `&Description=${ticket.workOrder.name}`;
  const stylesheetUrl = `&stylesheetURL=${getQuestionStylesheetUrl(stylesheet)}`;

  return instanceUrl.concat(lang, workOrderId, workType, engineFinished, woStatus, desc, stylesheetUrl);
};

const subscribeWindowMessages = (listener: (event: MessageEvent) => void) => {
  window.addEventListener('message', listener);

  return () => window.removeEventListener('message', listener);
};


export default {
  getQuestionsUrl,
  subscribeWindowMessages,
};
